<template>
  <div class="pirates-wrapper">
      <PiratesBand :language="language"/>

      <div class="pirate-video-title">{{this.$locData.watchReese[language]}}</div>
      <SimpleVideoPlayer posterURL="https://audio-sea-of-stars.fra1.digitaloceanspaces.com/video/img-video-poster-reece.webp" videoId="M04ko3ZjJYM" />
      <PiratesInstruments :language="language"/>
      <div class="clouds"></div>
  </div>
</template>

<script>
import SimpleVideoPlayer from './simple-video-player.vue'
import PiratesBand from './pirates-band.vue'
import PiratesInstruments from './pirates-instruments.vue'

export default {
  name: 'PiratesFold',
  props: {
    language: String
  },
  components: {
     PiratesBand,
     SimpleVideoPlayer,
     PiratesInstruments
  },
  data (){
    return{

    }
  },
  mounted:function() {

  }
}
</script>

<style>

.pirates-wrapper{
  background-color:#00121e;
  padding:47px 0 212px 0;
  position:relative;
  overflow-x:hidden;
}

.pirate-video-title{
  font-size:40px;
  text-align: center;
  font-family:"orpheuspro";
  letter-spacing: -0.8px;
  line-height: 0.9;
  color:#fff;
  margin-top:64px;
  margin-bottom:32px;
}

.pirate-video-description{
  max-width:560px;
  margin:auto;
  font-size: 16px;
  line-height: 1.38;
  text-align: center;
  color: #e4e6ee;
  margin-bottom:20px;
}

.clouds {
    background-image:url("../assets/bg-pattern-sky-clouds-lg.png");
    background-size:1280px 368px;
    background-repeat:repeat-x;
    position:absolute;
    width:calc(100% + 1280px);
    height:368px;
    bottom: 0;
    left: 0;
    z-index:1;
    animation: clouds-bottom-slide 60s linear infinite;
}

  @keyframes clouds-bottom-slide {
     from { transform:translate3d(-1280px, 0, 0) }
     to { transform:translate3d(0, 0, 0) }
  }

/* MOBILE ------------------------------- */
@media screen and (max-width:767px) {
  .pirates-wrapper{
    padding-top:10px;
  }

  .pirate-video-title{
    text-align:left;
    margin-top:50px;
    margin-left:30px;
    margin-right:30px;
  }

  .pirate-video-description{
    font-size:20px;
    line-height:1.4;
    margin-left:30px;
    margin-right:30px;
    text-align:left;
  }

}

</style>
