import Vue from 'vue'
import App from './App.vue'
import VueYouTubeEmbed from 'vue-youtube-embed';

import locData from './content/static-content.json'

Vue.use(VueYouTubeEmbed);

Vue.config.productionTip = false

Vue.prototype.$locData = locData;

Vue.prototype.$svgToPause = function (el) {
    //console.log('$svgToPause', el);
    var ks = el.contentDocument.defaultView.KeyshapeJS;
    var timeline = ks.timelines()[0];
    timeline.range(0, 333);

    ks.globalPlay();
    timeline.play();
}

Vue.prototype.$svgToPlay = function (el) {
    //console.log('$svgToPlay', el);
    var ks = el.contentDocument.defaultView.KeyshapeJS;
    var timeline = ks.timelines()[0];
    timeline.range(333,667);

    ks.globalPlay();
    timeline.play();
}

Vue.prototype.$svgForcePause = function (el) {
  //console.log('$svgForcePause', el);
  
  var ks = el.contentDocument.defaultView.KeyshapeJS;
  var timeline = ks.timelines()[0];
     
  ks.globalPlay();
  timeline.pause(333);
  
}

Vue.prototype.$svgForcePlay = function (el) {
  //console.log('$svgForcePause', el);
  
  var ks = el.contentDocument.defaultView.KeyshapeJS;
  var timeline = ks.timelines()[0];
     
  ks.globalPlay();
  timeline.pause(0);
  
}

new Vue({
  render: h => h(App),
}).$mount('#app')
