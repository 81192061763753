<template>
  <div class="pirates-instruments-wrapper">

      <!-- Content -->
      <div class="content-wrapper">


          <div class="instrument-image-wrapper">
            <img class="content-img" :src="this.currentInstrument.imageUrl" @load="loadingDone"/>
            <div class="loading-wrapper">
              <img class="loading-wheel" :src="require('@/assets/img-audio-loader.svg')"/>
            </div>
          </div>

        <div class="content-text">
          <span>{{this.$locData.piratesInstruments[language]}}</span>
          <h2>{{this.currentInstrument.name[language]}}</h2>
          <p>{{this.currentInstrument.description[language]}}</p>
          <div class="btn-sample" v-bind:class="{playing:playing}" v-on:click="toggleSample()" >
            <div class="btn-sample-img">
              <object id="play-pause-button" v-bind:data="playPauseSVG" type="image/svg+xml" style="pointer-events: none;"></object>
            </div>
            <div class="label" v-if="!playing">{{this.$locData.playSample[language]}}</div>
            <div class="label" v-if="playing">{{this.$locData.pauseSample[language]}}</div>
          </div>
        </div>
      </div>

      <!-- Nav -->
      <div class="navigation-wrapper">
        <div class="btn-instrument" v-for="(instrument, ind) in instruments" v-bind:key="ind" v-on:click="changeInstrument(ind)" v-bind:class="{active:isActive(ind)}">
            <img class="icon" :src="instrument.iconUrl" />
            <div class="label">{{instrument.name[language]}}</div>
        </div>
      </div>

      <!-- Mobile Title -->
      <div class="mobile-title">
        <div></div>
        <div>{{this.$locData.piratesInstruments[language]}}</div>
        <div></div>
      </div>

  </div>
</template>

<script>
import instrumentsData from '../content/instruments.json'
import { EventBus } from '../event-bus.js'
import { gsap } from "gsap";

export default {
  name: 'PiratesInstruments',
  props: {
    language: String
  },
  components: {

  },
  data (){
    return{
      instruments: null,
      currentIndex: 0,
      currentInstrument:null,
      loading:false,
      audio:null,
      playing:false,
      playPauseSVG: require('@/assets/btn-play-to-pause-58.svg?global=paused')
    }
  },
  beforeMount:function() {
     this.instruments = instrumentsData.data;
     this.currentInstrument = instrumentsData.data[0];

     this.audio = new Audio();
     this.audio.loop = false;

     this.audio.addEventListener("ended", () => {this.pauseSample();})

  },
  mounted:function() {
    EventBus.$on('play', (data) => {
      if (data.type != "instrument")
      {
        this.pauseSample();
      }
    })
  },
  methods: {
    pauseSample: function() {
      this.audio.pause();
      this.svgToPlay();
      this.playing = false;
    },
    playSample: function() {
      this.audio.src = this.currentInstrument.sampleUrl;
      this.audio.play();
      this.playing = true;
      this.svgToPause();
      EventBus.$emit('play', {type:'instrument',url:null});
    },
    toggleSample:function(){
        if (this.playing) {
          this.pauseSample();
        } else {
         this.playSample();
        }
    },
    changeInstrument:function(index){
      gsap.to('.content-img', 0.3, {autoAlpha:0, onComplete:this.loadInstrument, onCompleteParams:[index]});
      gsap.to('.loading-wrapper', 0.3, {autoAlpha:1});
      if (this.playing) {
        this.pauseSample();
      }
    },
    loadInstrument:function(index){
      this.currentIndex = index;
      this.currentInstrument = instrumentsData.data[this.currentIndex];
      this.loading = true;
    },
    isActive:function(index) {
      if (index == this.currentIndex){
        return true;
      }else{
        return false;
      }
    },
    loadingDone:function() {
      this.loading = false;
      gsap.to('.content-img', 0.5, {autoAlpha:1});
      gsap.to('.loading-wrapper', 0.1, {autoAlpha:0});

    },
    svgToPause:function(){
        this.$svgToPause(document.getElementById("play-pause-button"));
    },
    svgToPlay:function(){
        this.$svgToPlay(document.getElementById("play-pause-button"));
    }
  }
}
</script>

<style scoped>

.pirates-instruments-wrapper{
  max-width:1080px;
  margin:auto;
  margin-top:88px;
  position:relative;
  z-index:10;
}

.instrument-image-wrapper {
  display:block;
  position:relative;
}

.mobile-title{
  display:none;
}

/* CONTENT -----------------------*/
.content-wrapper{
  display:flex;
  flex-direction:row;
}

.content-img {
  width:490px;
}

.loading-wrapper{
  position:absolute;
  width:100%;
  height:100%;
  top:0;
  left:0;
  display:flex;
  justify-content:center;
  align-items:center;
  visibility:hidden;
}

.loading-wheel {
  height:80px;

}

.content-text{
  text-align:left;
  max-width:470px;
  padding-top:40px;
}

.content-text span{
  display:block;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 6px;
  color: #d69a62;
  text-transform:uppercase;
}

.content-text h2{
  font-family: OrpheusPro;
  font-size: 50px;
  font-weight: bold;
  line-height: 0.9;
  letter-spacing: -1px;
  color: #fff;
  margin:8px 0;
}

.content-text p{
  font-size: 16px;
  line-height: 1.38;
  letter-spacing: normal;
  color: #e4e6ee;
}

.btn-sample {
  background:#25a4ff;
  border-radius:7px;
  padding:5px;
  margin-top:16px;

  display:inline-flex;
  flex-direction:row;
  align-items:center;
  cursor:pointer;
}

.btn-sample-img{
  background:#00121E;
  width:40px;
  height:40px;
  border-radius:4px;
}

.btn-sample .label{
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  padding:0 9px 0 9px;
}

.btn-sample:hover{
  background:#41B0FF;
}

.btn-sample.playing {
  background-color:#E81954;
}

#play-pause-button {
  width:40px;
  height:40px;
}

/* NAVIGATION -----------------------*/
.navigation-wrapper{
  background:#00121E;
  padding:4px;
  border-radius:8px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.36);
  display:flex;
}

.btn-instrument{
  height:70px;
  border-radius:7px;
  color: #7690a0;
  width:17%;

  display:flex;
  align-items:center;
  justify-content:center;
  cursor:pointer;
}

.btn-instrument .label{
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform:uppercase;
  margin-left:8px;
}

.btn-instrument:hover{
  background:#041C2D;
}

.btn-instrument.active{
  background:#062337;
  color:#fff;
}

/* MEDIUM DESKTOP ------------------------------- */
@media screen and (max-width:1080px) {
  .pirates-instruments-wrapper{
    max-width:calc(100% - 30px);
  }

  .btn-instrument{
    width:auto;
    flex-grow:1;
  }
}

/* MOBILE ------------------------------- */
@media screen and (max-width:767px) {
  .pirates-instruments-wrapper{
    display:flex;
    flex-direction:column-reverse;
    margin-top:20px;
  }
  .content-wrapper{
    flex-direction:column;
  }

  .content-img{
    max-width:100%;
    width:100%;
  }

  .content-text{
    padding-top:0px;
    margin-top:-10px;
    margin-left:15px;
    margin-right:15px;
  }

  .content-text span{
    display:none;
  }

  .content-text p{
    font-size:20px;
    line-height:1.4;
  }

  .navigation-wrapper{
     background:#041C2D;
     margin-bottom:10px;
  }

  .btn-instrument{
    height:56px;
  }
  .btn-instrument .label{
    display:none;
  }

  .btn-instrument .icon{
    height:36px;
    width:36px;
  }

  .btn-instrument.active{
    background:#073250;
  }

  .btn-instrument.active .icon{
   filter: brightness(300%);
  }

  .mobile-title{
    display:block;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 6px;
    color: #d69a62;
    text-transform:uppercase;
    margin:30px auto;
    display:flex;
    align-items:center;
  }

  .mobile-title div:first-child, .mobile-title div:last-child{
    width:16px;
    height:2px;
    background:#d69a62;
    margin:0 15px;
  }

  .btn-sample {
    width:100%;
    display:flex;
  }

  .btn-sample .label{
    font-size: 20px;
    width:calc(100% - 58px - 58px);
    text-align:center;
  }

  .btn-sample img{
    width:58px;
    height:58px;
  }

}

</style>
