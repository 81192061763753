<template>
<div class="music-player-fold" v-bind:class="{ night: tod=='night'}">

  <div class="border" id="top"></div>
  <div class="border" id="bottom"></div>

    <div class="music-player-wrapper">
    <div class="music-player-left-wrapper">
      <div class="music-player-title" v-html="this.$locData.theMusicOfSeaofStars[language]">
        {{this.$locData.theMusicOfSeaofStars[language]}}
      </div>

      <div class="track-list-wrapper">
        <li v-for="(track, ind) in musicData" v-bind:key="ind">
          <div class="track-wrapper" v-on:click.prevent="changeTrack(ind)" v-bind:class="{ active: activeTrackId==ind, playing: musicPlaying == true}">
            <img class="track-thumbnail" :src="track.thumbnailUrl">
            <div class="play-button-wrapper" @click.stop="toggleMusic()">
              <object v-bind:id="track.uniqueId" v-bind:data="playPauseSVG" type="image/svg+xml" style="pointer-events: none;"></object>
            </div>
            <div class="track-written-content">
              <div class="track-name" v-bind:class="{ active: activeTrackId==ind}">{{track.name[language]}} </div>
              <div class="composed-by" v-bind:class="{ active: activeTrackId==ind}">{{$locData.composedBy[language]}} {{track.composer}} </div>
            </div>
            <div class="switch-tod" v-if="activeTrackId==ind" v-on:click.stop="toggleTod()" v-bind:class="{ playing: musicPlaying==true}">
              <div class="knob"></div>
            </div>
          </div>
        </li>
      </div>
      <!--
      <div class="ost-wrapper">
        <img id="ost-cover" src="@/assets/img-soundtrack-cover.jpg" />
        <div id="ost-description">
          <p id="ost-description-title">GET THE FULL SOUNDTRACK</p>
          <p id="ost-description-text">Sea of Stars original soundtrack composed by Eric W. Brown & Guest Composer Yasunori Mitsuda will soon be available on Spotify and Steam. </p>
        </div>
      </div> -->

    </div>

    <div class="music-player-right-wrapper">
      <div class="music-video">
        <div id="loading-music-video" v-bind:class="{ night: tod=='night'}">
           <img id="loading-wheel" :src="require('@/assets/img-audio-loader.svg')"/>
        </div>
        <video id="video-tod" width="850" height="720" loop autoplay muted >
          <source :src="activeTrackData.video[this.tod]" type="video/mp4" >
          Your browser does not support the video tag.
        </video>
        <img v-if="tod=='day'" src="@/assets/img-corner-ornament-screenshot-large-tl.png" class="video-corner tl" />
        <img v-if="tod=='day'" src="@/assets/img-corner-ornament-screenshot-large-tr.png" class="video-corner tr" />
        <img v-if="tod=='day'" src="@/assets/img-corner-ornament-screenshot-large-bl.png" class="video-corner bl" />
        <img v-if="tod=='day'" src="@/assets/img-corner-ornament-screenshot-large-br.png" class="video-corner br" />

        <img v-if="tod=='night'" src="@/assets/img-corner-ornament-screenshot-large-night-tl.png" class="video-corner tl" />
        <img v-if="tod=='night'" src="@/assets/img-corner-ornament-screenshot-large-night-tr.png" class="video-corner tr" />
        <img v-if="tod=='night'" src="@/assets/img-corner-ornament-screenshot-large-night-bl.png" class="video-corner bl" />
        <img v-if="tod=='night'" src="@/assets/img-corner-ornament-screenshot-large-night-br.png" class="video-corner br" />
      </div>
    </div>

  </div>

</div>
</template>

<script>
import musicData from '../content/music.json'
import { EventBus } from '../event-bus.js'
import { gsap } from "gsap";


export default {
  name: "music-player.vue",
  props: {
    language: String
  },
  components: {

  },
  data () {
    return {
      musicData: null,
      activeTrackData: null,
      activeTrackId: null,
      musicPlaying:false,
      audioDay:null,
      audioNight:null,
      video:null,
      loading:false,
      tod: "day",
      playPauseSVG: require('@/assets/btn-play-to-pause-58.svg?global=paused'),
      isIos:false
    }
  },
  methods: {
    changeTrack: function(id) {
      if(id ==  this.activeTrackId){
        return; //Cancelling the routine if user clicks on an already selected track
      }

      this.activeTrackId = id;
      this.activeTrackData = this.musicData[this.activeTrackId];

      this.audioDay.src = this.musicData[this.activeTrackId].music["day"];
      this.audioDay.load();

      this.audioNight.src = this.musicData[this.activeTrackId].music["night"];
      this.audioNight.load();

      if (this.musicPlaying) {
        EventBus.$emit('play', {type:'music', url:null});
        this.audioDay.play();
        this.audioNight.play();
      }

      this.changeVideo();
    },
    toggleMusic: function() {
      if (this.musicPlaying) {
        this.musicPlaying = false;
        this.audioDay.pause();
        this.audioNight.pause();
        this.svgToPlay();
        EventBus.$emit('pause');
      } else {
        this.musicPlaying = true;
        this.audioDay.play();
        this.audioNight.play();
        this.svgToPause();
        EventBus.$emit('play', {type:'music', url:null});
      }
    },
    toggleTod: function() {
      if (this.tod == "day"){
         this.tod ="night";
         this.audioNight.currentTime = this.audioDay.currentTime; //Force Sync

         if(this.isIos){
          this.audioDay.muted = true;
          this.audioNight.muted = false;
         }else{
          gsap.to(this.audioDay, {duration: 1, volume: 0, delay:0.1});
          gsap.to(this.audioNight, {duration: 1, volume: 1, delay:0.1});
         }
         
      } else {
        this.tod ="day";
        this.audioDay.currentTime = this.audioNight.currentTime; //Force Sync

        if(this.isIos){
          this.audioDay.muted = false;
          this.audioNight.muted = true;
        }else{
          gsap.to(this.audioDay, {duration: 1, volume: 1,  delay:0.1});
          gsap.to(this.audioNight, {duration: 1, volume: 0,  delay:0.1});
        }
        
      }

      this.changeVideo();
    },
    changeVideo: function() {

      gsap.to(this.video, {duration: 0.3, opacity: 0, onComplete:this.loadNextVideo});
    },
    loadNextVideo:function(){
      this.video.setAttribute('src', this.activeTrackData.video[this.tod]);
      this.video.setAttribute('type', 'video/mp4');
      this.video.load();
      this.video.addEventListener("loadeddata", this.loadVideoComplete);
    },
    loadVideoComplete:function(){
      gsap.to(this.video, {duration: 0.3, opacity: 1});
    },
    svgToPause:function(){
      for (const track of this.musicData) {
        let uniqueId = track.uniqueId;
        this.$svgToPause(document.getElementById(uniqueId));
      }
    },
    svgToPlay:function(){
      for (const track of this.musicData) {
        let uniqueId = track.uniqueId;
        this.$svgToPlay(document.getElementById(uniqueId));
      }
    }
  },
  beforeMount() {
    this.isIos = /iPad|iPhone|iPod/.test(navigator.userAgent);
    this.musicData = musicData.data;

    this.audioDay = new Audio();
    this.audioDay.loop = true;
    this.audioDay.volume = 1;

    this.audioNight = new Audio();
    this.audioNight.loop = true;
    this.audioNight.volume = 0;

    if(this.isIos){
      this.audioNight.volume = 1;
      this.audioNight.muted = true;
    }

     this.changeTrack(0);
  },
  mounted(){
    this.video = document.getElementById('video-tod');
    this.changeTrack(0);

    EventBus.$on('play', (data) => {
      console.log(data)
      if (data.type != "music"){
        this.musicPlaying = false;
        this.audioDay.pause();
        this.audioNight.pause();
        this.svgToPlay();
        console.log('Data should not be music, but its', data.type)
      }
    })
  }
}
</script>

<style scoped>





.music-player-fold{
  background-image:url("../assets/bg-pattern-paper.webp");
  background-size:500px 500px;
  background-color:#F8F0E5;
}



.music-player-fold.night{
  background-image:url("../assets/bg-pattern-paper-night.webp");
  background-color:#09131F;
}

.border {
  position: absolute;
  display:block;
  width:100%;
  height:7px;
  pointer-events: none;
}

.border#top {
  top:-7px;
  background-image:url("../assets/bg-pattern-paper-edge-top@2x.png");
  background-size:1450px 7px;
}

.border#bottom {
  bottom:-7px;
  background-image:url("../assets/bg-pattern-paper-edge-bottom@2x.png");
  background-size:1450px 7px;
  z-index:2;
}

.night .border#top{
  background-image:url("../assets/bg-pattern-paper-night-edge-top@2x.png");
}

.night .border#bottom{
  background-image:url("../assets/bg-pattern-paper-night-edge-bottom@2x.png");
}

.music-player-wrapper {
  max-width:1312px;
  display:flex;
  flex-direction: row;
  align-items: center;
  justify-content:center;
  gap:70px;
  margin:auto;
  padding:60px 0;
}


/* LEFT COLUMN --------------------- */
.music-player-left-wrapper {
  width:360px;
}

.music-player-title {
  font-size:50px;
  text-align: left;
  font-family:"orpheuspro";
  letter-spacing: -1px;
  line-height: 0.9;
  color:#584b3b;
  margin-bottom:24px;

  transition: color 0.3s;
}

.night .music-player-title{
  color:#bd9e79;
}

li {
  list-style: none;
}

.track-wrapper {
  display:flex;
  flex-direction: row;
  align-items: center;
  padding:10px;
  cursor:pointer;
  position:relative;
}

.track-wrapper.active {
  background-color: rgba(206, 185, 154, 0.25);
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  border-top-left-radius: 10px;
  border-bottom-left-radius:10px;
}

.night .track-wrapper.active {
  background-color: #141f30;
}

.track-written-content {
  display:flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  margin-left:10px;
  width:200px;
}

.track-thumbnail {
  width:58px;
  height:58px;
  border-radius: 4px;

  image-rendering: auto;
  -ms-interpolation-mode: nearest-neighbor;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  image-rendering: pixelated;

  display:block;
}

.track-wrapper.active .track-thumbnail{
  display:none;
}

.play-button-wrapper {
  width:0;
  height:58px;
  background-color:#25a4ff;
  border-radius:4px;
}

.track-wrapper.active .play-button-wrapper {
  width:58px;
}

.track-wrapper.playing .play-button-wrapper{
  background-color:#E81954;
}

.track-name {
  color:#584b3b;
  font-size:18px;
  font-weight:500;
  margin-bottom: 2px;
  transition: color 0.3s;
}

.night .track-name {
 color:#bd9e79;
}

.night .track-name.active {
  color:#e9d5bd;
}

.composed-by {
  color:#bd9e79;
  font-size:15px;
  font-weight:200;
  transition: color 0.3s;
}

.composed-by.active {
  color:#584B3B;
}

.night .composed-by {
  color:#85725B;
}

.night .composed-by.active {
  color:#bd9e79;
}



/* SWITCH TOD --------------------- */

.switch-tod {
  width:64px;
  height:40px;
  border-radius:20px;
  background:#7D7266;
  box-shadow: inset 0 1px 3px 0 rgba(53, 29, 6, 0.6);
  position:absolute;
  right:10px;
}

.night .switch-tod{
  background:#000000;
}

.switch-tod .knob{
  width:36px;
  height:36px;
  border-radius:18px;
  background-color:#25A4FF;
  background-image:url("../assets/btn-tod-switch-icons.svg");
  background-repeat:no-repeat;
  background-position-x:0px;
  margin:2px;

  transition: margin-left 0.15s cubic-bezier(.54,0,.45,1.01), background-position-x 0.3s;
}

.switch-tod.playing .knob{
  background-color:#E81954;
}

.night .switch-tod .knob{
  margin-left:26px;
  background-color:#22334D;
  background-position-x:-36px;
}

/* OST FOOTER --------------------- */
.ost-wrapper {
  margin-top:20px;
  padding-top:20px;
  border-top:1px solid #D9CEC1;
  text-align:left;
  display: flex;
  gap: 10px;
}

.night .ost-wrapper{
  border-top:1px solid #453F3A;
}

.ost-wrapper #ost-cover{
  width:100px;
  height: 100px;
  border: 10px solid #E0D1BA;
  border-radius: 4px;
}

.night .ost-wrapper #ost-cover{
  border: 10px solid #141f30;
}


#ost-description{
}

#ost-description-title{
  color: #584B3B;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 13px;
  letter-spacing: 1px;
  margin-top: 10px;
}

.night #ost-description-title{
  color: #bd9e79;
}

#ost-description-text{
  color: #BD9E79;
  font-size: 14px;
  margin-top: 8px;
  line-height: 1.4;
}

.night #ost-description-text{
  color: #85725B;
}




/* WORD FROM COMPOSER --------------------- */
/*
.word-from-composer {
  margin-top:30px;
  padding-top:18px;
  border-top:1px solid #D9CEC1;
  text-align:left;
}

.night .word-from-composer{
  border-top:1px solid #453F3A;
}

.word-composer-title-wrapper {
  display:flex;
  align-items: center;
  margin-left:10px;
}

.word-composer-title {
  display:inline-block;
  text-align:left;
  color:#584b3b;
  text-transform: uppercase;
  font-size:13px;
  font-weight:600;
  letter-spacing: 1px;
  margin-bottom: 2px;
  transition: color 0.3s;
}

.night .word-composer-title{
  color:#85715A;
}

.composer-thumbnail {
  width:30px;
  height:30px;
  margin-right:8px;
  border-radius:3px;
}

.commentary {
  margin-left:10px;
  text-align:left;
  font-size:14px;
  line-height: 1.5;
  color:#584b3b;
  margin-top:8px;
  transition: color 0.3s;
}

.night .commentary{
  color:#85715A;
}
*/

/* VIDEO --------------------- */

.music-video{
  position:relative;
  /*background-color: #00121e;*/
  height:720px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}

#loading-wheel {
  display:block;
  position:absolute;
  margin:auto;
  z-index:1;
}

#video-tod {
  position:relative;
  z-index:2;
}

#loading-music-video {
  display:flex;
  position:absolute;
  align-items: center;
  justify-content: center;
  width:100%;
  height:100%;
  background:rgba(206, 185, 154, 0.25);
  z-index:1;
}

#loading-music-video.night {
  background-color:#09131F;
}

img.video-corner{
  position:absolute;
  image-rendering: auto;
  -ms-interpolation-mode: nearest-neighbor;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  image-rendering: pixelated;
  z-index:3;
}

img.video-corner.tl{
  top:-10px;
  left:-10px;
}

img.video-corner.tr{
 top:-10px;
 right:-10px;
}

img.video-corner.bl{
  bottom:-10px;
  left:-10px;
}

img.video-corner.br{
  bottom:-10px;
  right:-10px;
}

video {
  object-fit: cover;
  image-rendering: auto;
  -ms-interpolation-mode: nearest-neighbor;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  image-rendering: pixelated;
}

/* MEDIUM DESKTOP ----------------------- */
@media screen and (max-width:1300px) {
  .music-player-wrapper{
    gap:30px;
  }

  .music-video{
    width:700px;
    height:640px;
  }

  video{
    width:700px;
    height:640px;
  }
}

@media screen and (max-width:1150px) {
  .music-player-wrapper{
    gap:20px;
  }

  .music-video{
    width:600px;
  }

  video{
    width:600px;
  }
}


/* MOBILE ------------------------------- */
@media screen and (max-width:767px) {
  .music-player-wrapper{
    padding:30px 0;
  }

  .music-player-left-wrapper{
    width: 100%;
    margin:0 15px;
  }

  .music-player-title{
    text-align:center;
  }

  .track-name{
    font-size:20px;
  }

  .word-from-composer{
    margin-top:15px;
  }

  .word-composer-title{
    font-size:16px;
  }

  .commentary{
    font-size:16px;
  }

  .music-player-right-wrapper{
    display:none;
  }


}


</style>
