<template>
  <div class="mitsuda-inner-wrapper">
    <div>
      <img class="mitsuda-portrait" src="@/assets/img-mitsuda-portrait.webp">
    </div>

    <div class="special-collaboration">
      {{this.$locData.specialCollaboration[language]}}
    </div>

    <div class="news-from-mitsuda">
      {{this.$locData.newsFromMitsuda[language]}}
    </div>

    <div>
      <img class="dots" src="@/assets/img-mitsuda-stars.svg" />
    </div>


    <SimpleVideoPlayer posterURL="https://audio-sea-of-stars.fra1.digitaloceanspaces.com/video/img-video-poster-mitsuda.webp" videoId="1-ZB-IX-sfc" />

  </div>
</template>

<script>
import SimpleVideoPlayer from './simple-video-player.vue'

export default {
  name: "mitsuda-fold",
  props: {
    language: String
  },
  components: {
    SimpleVideoPlayer
  },
}
</script>

<style scoped>

.mitsuda-inner-wrapper {
  background-color: #00121e;
  background-image:url("../assets/bg-pattern-audio-2.svg"), url("../assets/img-corner-ornament-mitsuda-tl.svg"), url("../assets/img-corner-ornament-mitsuda-tr.svg");
  background-repeat:repeat-x, no-repeat, no-repeat;
  background-position: bottom, top 50px left 44px, top 50px right 44px;
  padding-top:80px;
  padding-bottom:76px;
}

.mitsuda-portrait {
  width:126px;
  height:126px;
  margin-bottom: 18px;
  box-shadow: 0 20px 20px -20px #000;
}

.special-collaboration {
  color:#d69a62;
  font-size:12px;
  font-weight:600;
  text-transform: uppercase;
  letter-spacing: 4px;
  margin-bottom:10px;
}

.news-from-mitsuda {
  font-size:50px;
  text-align: center;
  font-family:"orpheuspro";
  letter-spacing: -1px;
  line-height: 0.9;
  font-size:50px;
  color:white;

  max-width: 360px;
  margin: 0 auto 18px auto;
}

.intro-wrapper {
  display:flex;
  flex-direction: row;
  justify-content: center;
  margin:18px auto 50px auto;
  gap:15px;
}

.intro-text {
  width:340px;
  font-size:16px;
  text-align:left;
  line-height: 1.38;
  color: #e4e6ee;
}

.dots {
  display:inline-block;
  margin-bottom:48px;
}
/* MEDIUM DESKTOP ------------------------ */
@media screen and (max-width:1100px) {
.intro-text {
    width:30%;
  }
}

/* MOBILE ------------------------------- */
@media screen and (max-width:767px) {
  .mitsuda-inner-wrapper {
    background-image:url("../assets/img-corner-ornament-mitsuda-tl.svg"), url("../assets/img-corner-ornament-mitsuda-tr.svg");
    background-repeat:no-repeat, no-repeat;
    background-position: top 20px left 10px, top 20px right 10px;
    padding-bottom:20px;
  }

  .news-from-mitsuda {
    max-width:300px;
    margin-left:auto;
    margin-right:auto;
  }

  .intro-wrapper {
    flex-direction: column;
    padding:0 30px;
    margin-bottom:20px;
  }

  .intro-text {
    width:auto;
    font-size: 20px;
    line-height: 1.4;
    margin:0;
  }
}

</style>
