<template>
  <div class="collaborators-section-wrapper">

    <div class="collaborators-title">
      {{this.$locData.meetTheCollaborators[language]}}
    </div>

    <!-- Custom navigation for mobile -->
    <div class="collaborators-mobile-nav">
      <div v-for="(collaborator, ind) in collaborators" v-bind:key="ind" class="mobile-button" v-on:click="setActive(ind)" v-bind:class="{active: currentId==ind}">
        <div class="mobile-button-img-wrapper">
          <img :src="collaborator.mobilePortraitUrl" />
        </div>
        <img class="mobile-button-arrow" src="@/assets/img-collaborator-arrow.svg" />
      </div>
    </div>


    <div class="collaborators-list-view-wrapper">

        <CollaboratorsView v-for="(collaborator, ind) in collaborators" v-bind:key="ind"
        :id="ind"
        :name="collaborator.name"
        :title="collaborator.title"
        :portraitUrl="collaborator.portraitUrl"
        :backgroundUrl="collaborator.backgroundUrl"
        :description="collaborator.description[language]"
        :knownFor="collaborator.knownFor"
        :listenOn="collaborator.listenOn"
        />

    </div>
  </div>
</template>

<script>

import collaboratorsData from '../content/collaborators.json'
import CollaboratorsView from './collaborators-view.vue'
import { EventBus } from '../event-bus.js'


export default {
name: "collaborators-list-view",
  props:{
    language: String
  },
  components: {
    CollaboratorsView
  },
  data () {
    return {
     collaborators: null,
     currentId:0 //For mobile navigation only
    }
  },
  methods:{
    setActive:function(id) {
      this.currentId = id;
      EventBus.$emit('collaboratorClicked', this.currentId);
    }

  },
  mounted() {
    this.collaborators = collaboratorsData.data;
  }
}
</script>

<style scoped>

.collaborators-section-wrapper {
  background-color:#00121e;
  display:block;
  position:relative;
  background-image:url("../assets/bg-pattern-audio-1.svg");
  background-repeat:repeat-x;
  background-position: center 22px;
  padding-bottom:98px;
}

.collaborators-title {
  display:inline-block;
  color:#d69a62;
  font-family: "neusa-next-std-condensed";
  font-size:16px;
  font-weight:500;
  text-transform: uppercase;
  letter-spacing: 2px;
  background:#00121E;
  padding:16px 42px;
  margin-bottom:85px;
}


.collaborators-list-view-wrapper {
  max-width:1200px;
  margin:auto;
  display:flex;
  gap:8px;
  flex-direction: row;
  justify-content: center;
}

.collaborators-mobile-nav{
  display:none;
}

li {
  list-style: none;
}
/* SMALL DESKTOP ----------------------------- */

@media screen and (max-width:1350px) {
  .collaborators-list-view-wrapper {
    gap:4px;
  }
}


/* MOBILE ------------------------------- */
@media screen and (max-width:767px) {
  .collaborators-section-wrapper {
    padding-bottom:0px;
  }

  .collaborators-title {
    margin-bottom:18px;
  }

  .collaborators-list-view-wrapper{
    width:100%;
    justify-content:left;
    gap:0;
  }

  .collaborators-mobile-nav{
    display:flex;
    flex-direction:row;
    justify-content:center;
    gap:1%;
  }

  .mobile-button-img-wrapper{
    width:72px;
    height:72px;
    border:3px solid #000;
    border-color: transparent;
    border-radius:8px;
    padding:2px;
    background-color:#00121E;
    box-shadow: 0 20px 20px -20px #000;
  }

  .mobile-button.active .mobile-button-img-wrapper{
    border:3px solid rgba(255,255,255,1);
  }

  .mobile-button .mobile-button-img-wrapper img{
    max-width:100%;
    border-radius:4px;
    opacity:0.6;
  }

  .mobile-button.active .mobile-button-img-wrapper img{
    opacity:1;
  }

  .mobile-button-arrow{
    margin:8px 0;
    position:relative;
    visibility:hidden;
    top:0;
    animation: arrow-bounce 0.5s ease-in-out infinite;
    animation-direction: alternate;
  }

  .mobile-button.active .mobile-button-arrow{
    visibility:inherit;
  }

  @keyframes arrow-bounce {
      from { transform:translate3d(0, 0, 0) }
      to { transform:translate3d(0, 5px, 0) }
  }

}

</style>
