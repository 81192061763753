<template>
  <div class="sfx-player-inner-border">
  <div class="sfx-player-inner-wrapper">
    <div class="left-column-wrapper">

      <div class="column-header">
        {{this.$locData.categories[language]}}
      </div>

      <div class="list-content-wrapper">
        <li v-for="(category, ind) in this.sfxCategories" v-bind:key="ind" >
          <div class="list-element" v-bind:class="{active:activeCategoryId==ind}" v-on:click="setActiveCategory(ind)">
            <div class="list-element-left-wrapper">
              <img class="category-icon" :src="category.icon">

              <div class="sfx-category-name-wrapper">
                {{category.name[language]}}
              </div>
            </div>

            <img class="arrow" src="@/assets/btn-sfx-arrow.svg" v-if="activeCategoryId==ind" />

          </div>
        </li>
      </div>

    </div>
    <div class="middle-column-wrapper">
      <div class="column-header">
        {{this.$locData.soundEffects[language]}}
      </div>

      <div class="list-content-wrapper">
        <li v-for="(category, ind) in this.sfxCategories[this.activeCategoryId].sfx" v-bind:key="ind" >
          <div class="list-element" v-bind:class="{active:activeSfxId==ind}" v-on:click="setActiveSfx(ind)">


            <div class="sfx-name-wrapper">
              {{category.name[language]}}
            </div>

            <!--
            <div v-if="activeSfxId==ind" v-on:click="toggleSfx()">
              <img v-if="!sfxPlaying" src="@/assets/btn-playback-58-play.svg" class="play-button"/>
              <img v-else src="@/assets/btn-playback-58-pause.svg" class="play-button" />
            </div>
            -->

          </div>
        </li>
      </div>

    </div>
    <div class="right-column-wrapper">

      <div class="loading-screen" id="loading">
        <img class="loading-wheel" :src="require('@/assets/img-audio-loader.svg')"/>
      </div>

      <video id="video-sfx" width="850" height="720" align="left" controls playsinline>
        <source :src="this.sfxCategories[this.activeCategoryId].sfx[this.activeSfxId].clipUrl" type="video/mp4">
        Your browser does not support the video tag.
      </video>

    </div>
  </div>
  </div>
</template>

<script>
import { EventBus } from '../event-bus.js'
import sfxData from "@/content/sfx.json";
import { gsap } from "gsap";

export default {
  name: "sfx-player.vue",
  props: {
    language: String
  },
  components: {

  },
  data () {
    return {
      sfxCategories: null,
      activeSfxId: 0,
      activeCategoryId:0,
      sfxPlaying: false,
      activeSfx: 0,
      videoDom: null,
      loadingVideo: false
    }
  },
  beforeMount() {
    this.sfxCategories = sfxData.categories;
    this.activeSfx = this.sfxCategories[this.activeCategoryId].sfx[this.activeSfxId];
  },
  mounted(){
    this.videoDom = document.getElementById('video-sfx');
    EventBus.$on('play', (data) => {
      if (data.type != "sfx")
      {
        this.videoDom.pause();
      }
    })
  },
  methods: {
    setActiveCategory: function(id) {
      this.activeCategoryId = id;
      this.activeSfxId = 0;
      this.changeVideo();
    },
    setActiveSfx: function(id) {
      this.activeSfxId = id;
      this.changeVideo();
    },
    changeVideo: function() {

      this.videoDom.pause();

      gsap.to(this.videoDom,{duration:0.3, opacity:0, onComplete:this.loadNextVideo});
    },
    loadNextVideo:function() {
      var objectPosition = 'left';
      if(this.sfxCategories[this.activeCategoryId].sfx[this.activeSfxId].align == "center"){
        objectPosition = 'center';
      }


      this.loadingVideo = true;
      this.videoDom.setAttribute('src', this.sfxCategories[this.activeCategoryId].sfx[this.activeSfxId].clipUrl);
      this.videoDom.setAttribute('type', 'video/mp4');
      this.videoDom.style.objectPosition = objectPosition;
      this.videoDom.load();
      this.videoDom.addEventListener("loadeddata", this.loadNextVideoComplete);
      this.videoDom.autoplay = true;
      EventBus.$emit('play', {type:'sfx',url:null});

      gsap.to("#loading",{duration:0.3, autoAlpha:1});
    },
    loadNextVideoComplete: function() {
      this.loadingVideo = false;
      this.videoDom.play();

      gsap.to(this.videoDom, {duration:0.3, opacity:1});
      gsap.to("#loading", {duration:0.3, autoAlpha:0});
    }

  }

}
</script>

<style scoped>

/* BUTTONS ----------------- */

li {
  list-style: none;
}

.list-element {
  display:flex;
  align-items: center;
  vertical-align: center;
  justify-content: space-between;
  text-align:left;
  text-transform: capitalize;
  font-family: "neusa-next-std-condensed";
  font-size:20px;
  color:#b2b7bb;
  width:228px;
  height:42px;
  border-radius:4px;
  cursor:pointer;
}

.list-element.active {
  background-color:#003052;
  color:#fff;
}

.list-element-left-wrapper {
  display:flex;
  flex-direction: row;
  align-items:center;
  margin-left:6px;
}

.category-icon {
  margin-right:7px;
}

.list-element.active .category-icon{
  filter: brightness(300%);
}

.arrow {
  margin-right:8px;
}

.sfx-name-wrapper {
  margin-left:20px;
}

/* LAYOUT ----------------- */
.sfx-player-inner-border{
  background-color:#00121e;
  border-radius:4px;
  padding:10px;
  box-shadow: 0 20px 20px -20px #352211;
  /*max-width:1292px;*/
  max-width:1400px;
  margin:auto;
}

.sfx-player-inner-wrapper {
  display:flex;
  flex-direction: row;
  background-color:#00121e;
  border:#6E4E3B 2px solid;
  border-radius:4px;
}


.left-column-wrapper {
  padding:10px;
  border-right:#6e4e3b 2px solid;
}

.middle-column-wrapper {
  padding:10px;
  border-right:#6e4e3b 2px solid;
}

.right-column-wrapper {
  text-align:left;
  display:block;
  position:relative;
  height:556px;
  width:100%;
}

.column-header {
  text-align:left;
  text-transform: uppercase;
  font-size:14px;
  font-weight: 500;
  letter-spacing: 1px;
  color:#bd9e79;
  padding:12px 8px 16px 8px;
}

#video-sfx {
  object-fit: cover;
  object-position: left;
  width: calc(100% - 16px);
  height: calc(100% - 16px);
  padding:8px;

   image-rendering: auto;
  -ms-interpolation-mode: nearest-neighbor;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  image-rendering: pixelated;
}

.loading-screen {
  position:absolute;

  display:flex;
  justify-content: center;
  text-align: center;
  align-items:center;

  z-index:20;
  width: calc(100% - 16px);
  height: calc(100% - 16px);
  background-color:#00121e;
  margin:8px;
  visibility:hidden;
  pointer-events: none;
}

.loading-wheel {
  height:80px;
}

/*
.play-button {
  display:block;
  align-items: center;
  height:34px;
  width:34px;
  margin-right:4px;
}*/

/* MEDIUM DESKTOP ------------------------ */
@media screen and (max-width:1300px) {
  .list-element{
    width:180px;
  }
}


/* MOBILE ------------------------------- */
@media screen and (max-width:767px) {
  .sfx-player-inner-border{
    padding:8px;
  }

  .sfx-player-inner-wrapper{
    flex-wrap:wrap;
    border:none;
  }

  .column-header{
    display:none;
  }

  .list-element{
    width:auto;
    padding:5px;
  }

  /* Categorie column */
  .left-column-wrapper{
    order:2;
    border:#6e4e3b 2px solid;
    border-right:none;
    border-radius: 4px 0 0 4px;
  }

  .sfx-category-name-wrapper{
    display:none;
  }

  .arrow{
    display:none;
  }

   /* SFX List column */
  .middle-column-wrapper{
    order:3;
    border:#6e4e3b 2px solid;
    border-radius: 0 4px 4px 0;
    width:calc(100% - 100px);
  }

  /* Video column */
  .right-column-wrapper{
    order:1;
    width:calc(100% + 16px);
    margin-left:-8px;
    margin-right:-8px;
    margin-top:-8px;
    height:400px;
    margin-bottom:8px;
  }

  #video-sfx {
    image-rendering: auto;
    width: calc(100%);
    height: calc(100%);
    padding:0;
  }

  .loading-screen {
    width: calc(100%);
    height: calc(100%);
    margin:0px;
  }

}

/* SMALL MOBILE ------------------------------- */
@media screen and (max-width:450px) {
   .right-column-wrapper{
      height:260px;
  }
}

</style>
