<template>
  <div id="app">
    <div id="sticky-nav">
      <a href="https://seaofstarsgame.co" id="back-button" class="sticky-button" >
        <img id="back-icn" :src="require('@/assets/branding-logo-sea-of-stars-white.svg')"/>
      </a>

      <!--
       <div id="language-selector">
          <div class="language-choice" v-bind:class="{active:language=='en'}" v-on:click="changeLanguage('en')">
            <img :src="require('@/assets/icn-language-en.svg')"/>
            <div class="underline"></div>
          </div>
          <div class="language-choice" v-bind:class="{active:language=='jp'}" v-on:click="changeLanguage('jp')">
            <img :src="require('@/assets/icn-language-jp.svg')"/>
            <div class="underline"></div>
          </div>
      </div>
    -->

    <div id="ost-buttons">
          <a class="ost-button steam" href="https://store.steampowered.com/app/2550490/Sea_of_Stars__OST/" target="_blank">
            <svg xmlns="http://www.w3.org/2000/svg" width="42" height="40" viewBox="0 0 42 40">
              <g>
                <path d="M20.972 4.002c-8.498 0-15.466 6.48-16.126 14.716l8.674 3.544c.736-.495 1.623-.787 2.579-.787.084 0 .168.005.253.008l3.86-5.523v-.079c0-3.327 2.734-6.032 6.1-6.032 3.363 0 6.101 2.708 6.101 6.036 0 3.328-2.738 6.033-6.1 6.033h-.142l-5.498 3.881c0 .069.005.14.005.212 0 2.5-2.043 4.528-4.571 4.528-2.205 0-4.067-1.564-4.492-3.636l-6.21-2.543C7.326 31.076 13.563 36 20.971 36c8.937 0 16.182-7.164 16.182-16S29.908 4 20.972 4v.002zm-5.987 24.28l-1.987-.813c.353.724.963 1.332 1.772 1.667 1.75.719 3.767-.101 4.494-1.833.355-.84.356-1.759.007-2.599-.349-.84-1.011-1.495-1.857-1.844-.841-.347-1.74-.332-2.532-.04l2.054.84c1.29.533 1.9 2 1.36 3.273-.535 1.276-2.018 1.88-3.31 1.349h-.001zm15.394-12.404c0-2.216-1.824-4.02-4.066-4.02-2.245 0-4.066 1.804-4.066 4.02 0 2.22 1.82 4.02 4.066 4.02 2.243 0 4.066-1.8 4.066-4.02zm-7.11-.007c0-1.669 1.366-3.021 3.054-3.021 1.684 0 3.056 1.352 3.056 3.021 0 1.668-1.372 3.02-3.056 3.02-1.69 0-3.054-1.352-3.054-3.02z" transform="translate(-494 -3547) translate(0 3498) translate(240 32) translate(228 10) translate(26 7)"/>
              </g>
            </svg>
            <div>OST ON STEAM</div>
          </a>
          <a class="ost-button spotify" href="https://open.spotify.com/album/0sKlNbj6o886BljTU9kFTT?si=MNS-BWuFRBqgYZGjgBmDCQ&nd=1&dlsi=6e6441f4d7874d8d" target="_blank">
            <svg width="42px" height="40px" viewBox="0 0 42 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <g>
              <path d="M21,4.03846154 C12.0786678,4.03846154 4.84615385,11.2707352 4.84615385,20.1920156 C4.84615385,29.114255 12.0786678,36.3461538 21,36.3461538 C29.9217289,36.3461538 37.1538462,29.114266 37.1538462,20.1920156 C37.1538462,11.2713083 29.9221037,4.03923313 20.9998015,4.03923313 L21,4.03846154 Z M28.4080513,27.3365266 C28.1179325,27.8124092 27.4979576,27.9615131 27.0236154,27.6715833 C23.230278,25.3531588 18.4556618,24.8294377 12.8326903,26.1139449 C12.2908448,26.2381817 11.7507299,25.8984843 11.627286,25.3568183 C11.503247,24.814976 11.841392,24.2748641 12.3845933,24.1514211 C18.5380348,22.7446171 23.8163026,23.3501266 28.074139,25.9519113 C28.5486686,26.243197 28.6991181,26.8623965 28.4078529,27.3369123 L28.4080513,27.3365266 L28.4080513,27.3365266 Z M30.3852468,22.9386826 C30.0198976,23.5314498 29.2448326,23.7174026 28.6528336,23.3534003 C24.3116652,20.6848732 17.6919833,19.911929 12.5560853,21.4709342 C11.8900023,21.6721204 11.1865081,21.2967427 10.9843508,20.6318319 C10.7837367,19.9657528 11.159315,19.2636075 11.8242296,19.0610655 C17.690837,17.2810223 24.9839099,18.1432613 29.9703173,21.2074368 C30.5625257,21.5720123 30.7496259,22.3474585 30.3850484,22.93887 L30.3852468,22.9386826 Z M30.5549972,18.3585344 C25.3479033,15.2662068 16.7589292,14.9818765 11.7881411,16.4905191 C10.9899394,16.7325994 10.1458169,16.2819918 9.90392252,15.4837838 C9.66202819,14.6852008 10.1122526,13.8416564 10.9110385,13.5989919 C16.6171545,11.8667871 26.1029179,12.2014581 32.0970144,15.759825 C32.8149815,16.1859291 33.0505157,17.1131798 32.6249712,17.8301838 C32.2005952,18.5481467 31.2708259,18.7848259 30.5551846,18.3585344 L30.5549972,18.3585344 L30.5549972,18.3585344 Z" id="Shape" ></path>
            </g>
            </svg>
            <div>OST ON SPOTIFY</div>
          </a>
      </div> 

    </div>


   <a href="https://sabotagestudio.com">
      <div id="sabotage-flag">
        <video id="video-flag" width="180" height="180" autoplay muted loop playsinline>
          <source :src="require('@/assets/img-sabotage-flag.mp4')" type="video/quicktime">
          <source :src="require('@/assets/img-sabotage-flag.webm')" type="video/webm">
        </video>
        <img id="img-flag" :src="require('@/assets/img-sabotage-flag-small.svg')">
      </div>
   </a>

    <div id="page-wrapper">

      <!-- Intro  -->
      <div class="fold-wrapper" id="intro">
        <Intro :language="language"/>
      </div>
      <!--  -->

      <!-- Collaborators  -->
      <div class="fold-wrapper" id="collaborators">
        <CollaboratorsListView :language="language"/>
      </div>
      <!--  -->

      <!-- Music -->
      <div class="fold-wrapper" id="music" >
        <MusicPlayer :language="language"/>
      </div>
      <!--  -->

      <!-- Mitsuda -->
       <div class="fold-wrapper" id="mitsuda">
        <Mitsuda :language="language"/>
      </div>
      <!--  -->

      <!-- SFX -->
      <div class="fold-wrapper" id="sfx">
        <SfxFold :language="language"/>
      </div>

      <!-- Pirates -->
      <div class="fold-wrapper" id="pirates">
        <Pirates :language="language"/>
      </div>
      <!-- -->

      <!-- Footer -->
      <Footer :language="language"/>
      <!-- -->
    </div>

    <div v-if="isLoading" id="loading">
      <div id="left"></div>
      <div id="right"></div>
      <img id="wheel" :src="require('@/assets/img-audio-loader.svg')"/>
    </div>

  </div>
</template>

<script>
import CollaboratorsListView from './components/collaborators-list-view.vue'
import Intro from './components/intro.vue'
import MusicPlayer from './components/music-player'
import Mitsuda from './components/mitsuda'
import SfxFold from './components/sfx-fold'
import Pirates from './components/pirates'
import Footer from './components/footer'
import { EventBus } from './event-bus.js'
import { gsap } from "gsap";

let languageList = ["en","jp"];
let defaultLanguage = "en";

export default {
  name: 'App',
  components: {
    CollaboratorsListView,
    Intro,
    MusicPlayer,
    Mitsuda,
    SfxFold,
    Pirates,
    Footer
  },
  data () {
    return {
      language:'en',
      isLoading:true
    }
  },
  methods: {
    changeLanguage: function(language) {
     if (languageList.includes(language)) {
       this.language = language;
     } else {
       this.language = defaultLanguage;
     }
    },
    tweenOutLoading:function(){
      let delay = 2.0;
      gsap.to('#wheel', 0.3, {autoAlpha:0, delay:delay});
      gsap.to('#left',  2.0, {width:0, delay:delay, ease:"power4.inOut"});
      gsap.to('#right', 2.0, {width:0, delay:delay, ease:"power4.inOut", onComplete:this.tweenOutLoadingComplete});

      gsap.delayedCall(delay+0.6, this.emitAppLoaded);
    },
    emitAppLoaded:function(){
      EventBus.$emit('appLoaded');
    },
    tweenOutLoadingComplete:function(){
      this.isLoading = false;
    },
    checkStickyDisplay:function(){
      var collaboratorsY = document.getElementById('collaborators').getBoundingClientRect().top;

      if (collaboratorsY < 0) {
        this.domStickyOST.className = "show"
      } else {
        this.domStickyOST.className = "hide"
      }
    }
  },
  mounted: function () {
    this.changeLanguage(window.location.pathname.split('/').pop());
    this.tweenOutLoading();

    this.domStickyOST = document.getElementById('ost-buttons');
    window.addEventListener("scroll", this.checkStickyDisplay);
    this.checkStickyDisplay();
  }
}
</script>

<style scoped>
@import url('https://use.typekit.net/glp2ipl.css');


#app {
  font-family: "neusa-next-std-condensed", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #fff;
  background: #026477;
}

#loading{
  width:100%;
  height:100%;
  position:fixed;
  display:flex;
  justify-content:center;
  align-content:center;

  top:0;
  left:0;
  z-index:999;
}

#loading #left, #loading #right{
  position:absolute;
  width:51%;
  height:100%;
  top:0;
  background: #00121e;
  z-index:1;
}

#loading #left{
  left:0;
}

#loading #right{
  right:0;
}

#loading #wheel{
  width:80px;
  z-index:999;
}


#ost-buttons{
  display:inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left:5px;;
  background:rgba(0,40,61,0.95);
  border-radius: 2px;
  position: relative;
  transition: all 0.25s;
}

#ost-buttons.show{
  box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5);
  top: 0px;
}

#ost-buttons.hide{
  box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0);
  top: -60px;
}

.ost-button{
  height: 100%;
  color: #fff;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 0 12px 0 8px;
  cursor: pointer;
  
}

.ost-button:first-child{
  border-right:1px solid #1A5B79;
}

.ost-button svg{
  width: 35px;
  height: 34px;
}

.ost-button.steam svg{
  fill: #fff;
}

.ost-button.spotify svg{
  fill: #fff;
}

.ost-button.disabled{
  color: #88AABB;
  cursor: not-allowed;
}

.ost-button.disabled svg{
  fill: #88AABB;
}



/*
#language-selector{
  display:inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left:5px;;
  background:rgba(0,40,61,0.95);
  border-radius: 2px;
  box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5);
}

.language-choice {
  height:48px;
  padding:0 10px;
  cursor:pointer;
  display:flex;
  align-items:center;;
  justify-content:center;
}

.language-choice:first-child{
  border-right:1px solid #1A5B79;
}

.language-choice .underline{
  width:0;
  height:2px;
  background:#fff;
  position:absolute;
  bottom:0;
  margin:auto;
  transition: width 0.2s ease-out;
}

.language-choice img{
  opacity:0.5;
  transition: opacity 0.2s ease-out;
}

.language-choice:hover img{
  opacity:0.75;
}


.language-choice.active img{
  opacity:1;
}

.language-choice.active .underline{
  width:20px;
}
*/


#sticky-nav{
  position:fixed;
  display:flex;

  z-index:999;
  top:12px;
  margin-left:12px;
}

.sticky-button {
  display:flex;
  align-items:center;
  height:48px;
  background:rgba(0,40,61,0.95);
  color:white;
  padding:0 16px 0 16px;
  border-radius: 2px;
  cursor:pointer;
  box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5);
}


#back-icn{
  height:25px;
}

#sabotage-flag {
  position:absolute;
  top:0;
  right:20px;
  z-index:999;
}

#sabotage-flag #img-flag{
  display:none;
}

#page-wrapper {

}

.fold-wrapper{
  width:100%;
  position:relative;
}

/* MOBILE ------------------------------- */
@media screen and (max-width:767px) {
  #back-button{
    display:none;
  }

  #sticky-nav{
    position:absolute;
  }

  #sabotage-flag {
    right:0;
  }

  #sabotage-flag #img-flag{
    display:block;
  }

  #sabotage-flag #video-flag{
    display:none;
  }
}

</style>
