<template>
  <div class="pirates-band-wrapper">
      <div class="content-wrapper">

        <!--Left -->
        <div class="content-left" v-bind:class="{pirate:isPirateVersion, playing:isPlaying}">
          <img class="background" src="@/assets/bg-pirate-band-circle.png" />

            <img class="pirates-concept" src="@/assets/img-pirate-band-concept-art.webp" />
            <div class="pirates-sprites">
                <img v-if="isPlaying" src="@/assets/img-pirate-sprite-yolande.gif" id="yolande" />
                <img v-if="isPlaying" src="@/assets/img-pirate-sprite-valtraid.gif" id="valtraid" />
                <img v-if="isPlaying" src="@/assets/img-pirate-sprite-keenathan.gif" id="keenathan" />
                <img v-if="isPlaying" src="@/assets/img-pirate-sprite-squid.gif" id="squid" />

                <img v-if="!isPlaying" src="@/assets/img-pirate-sprite-yolande.png" id="yolande" />
                <img v-if="!isPlaying" src="@/assets/img-pirate-sprite-valtraid.png" id="valtraid" />
                <img v-if="!isPlaying" src="@/assets/img-pirate-sprite-keenathan.png" id="keenathan" />
                <img v-if="!isPlaying" src="@/assets/img-pirate-sprite-squid.png" id="squid" />
            </div>

        </div>

        <!-- Right -->
        <div class="content-right">
          <span>{{this.$locData.introducing[language]}}</span>
          <h2>{{this.$locData.pirateBand[language]}}</h2>
          <p>{{this.$locData.pirateBandIntro[language]}}</p>

          <div class="btn-playback" v-bind:class="{pirate:isPirateVersion, playing:isPlaying}">
              <div class="btn-playback-img" v-on:click="togglePlayback()">
                <object id="play-pause-pirates" v-bind:data="playPauseSVG" type="image/svg+xml" style="pointer-events: none;"></object>
              </div>
              <div class="btn-playback-text" v-on:click="togglePlayback()">
                <div class="title">{{this.currentTitle[language]}}</div>
                <div class="composer">{{this.currentComposer[language]}}</div>
              </div>
              <div class="btn-playback-switch" v-on:click="toggleVersion()">
                <div class="btn-playback-knob"></div>
              </div>
          </div>

        </div>
      </div>
  </div>
</template>

<script>
import { gsap } from "gsap";
import { EventBus } from '../event-bus.js'

export default {
  name: 'PiratesBand',
  props: {
    language: String
  },
  components: {

  },
  data (){
    return{
        normalTitle: {
          "en":"Mountain Trail - Original Version",
          "jp":"マウンテントレイル - オリジナルバージョン"
        },
        normalComposer:{
          "en":"Composed by Eric W. Brown",
          "jp":"作曲:エリック・W・ブラウン",
        },
        normalURL:"https://audio-sea-of-stars.fra1.digitaloceanspaces.com/pirates/mountain-trail-day.mp3",
        pirateTitle:{
          "en":"Mountain Trail - Pirate Version",
          "jp":"マウンテントレイル - 海賊版"
        },
        pirateComposer:{
          "en":"Performed by Reece Miller",
          "jp":"演じる:リース・ミラー",
        },
        pirateURL:"https://audio-sea-of-stars.fra1.digitaloceanspaces.com/pirates/mountain-trail-pirate.mp3",
        audioNormal:null,
        audioPirate:null,
        isPlaying:false,
        isPirateVersion:false,
        currentTitle:null,
        currentComposer:null,
        playPauseSVG: require('@/assets/btn-play-to-pause-58.svg?global=paused'),
        isIos:false
    }
  },
  beforeMount:function() {
    this.isIos = /iPad|iPhone|iPod/.test(navigator.userAgent);
    this.currentTitle = this.normalTitle;
    this.currentComposer = this.normalComposer;

    this.audioNormal = new Audio(this.normalURL);
    this.audioNormal.loop = true;
    this.audioNormal.load();
    this.audioNormal.volume = 1;

    this.audioPirate = new Audio(this.pirateURL);
    this.audioPirate.loop = true;
    this.audioPirate.load();
    this.audioPirate.volume = 0;

    if(this.isIos){
      this.audioPirate.volume = 1;
      this.audioPirate.muted = true;
    }

    EventBus.$on('play', () => {
      if (this.isPlaying) {
        this.pausePlayback();
      }
    })

  },
  methods: {
    toggleVersion:function(){
        if(this.isPirateVersion){
          this.isPirateVersion = false;
          this.currentTitle = this.normalTitle;
          this.currentComposer = this.normalComposer;

          if(this.isIos){
            this.audioNormal.muted = false;
            this.audioPirate.muted = true;
          }else{
            gsap.to(this.audioNormal, {duration: 1, volume: 1});
            gsap.to(this.audioPirate, {duration: 1, volume: 0});
          }
        
        }else{
          this.isPirateVersion = true;
          this.currentTitle = this.pirateTitle;
          this.currentComposer = this.pirateComposer;

          if(this.isIos){
            this.audioNormal.muted = true;
            this.audioPirate.muted = false;
          }else{
            gsap.to(this.audioNormal, {duration: 1, volume: 0});
            gsap.to(this.audioPirate, {duration: 1, volume: 1});
          }
         
        }
    },
    togglePlayback:function(){
        if(this.isPlaying){
          this.pausePlayback();
        }else{
          this.startPlayback();
        }
    },
    pausePlayback:function(){
      this.audioNormal.pause();
      this.audioPirate.pause();
      this.isPlaying = false;
      this.$svgToPlay(document.getElementById("play-pause-pirates"));
    },
    startPlayback:function(){
      EventBus.$emit('play', {type:'pirate',url:null});

      this.audioNormal.play();
      this.audioPirate.play();
      this.isPlaying = true;
      this.$svgToPause(document.getElementById("play-pause-pirates"));



    }
  }
}
</script>

<style scoped>

/* PLAYBACK BUTTON --------- */
.btn-playback{
  max-width:390px;
  margin-top:20px;
  padding:10px;
  background:#25A4FF;
  border-radius:10px;
  display:flex;
  align-items:center;
  position:relative;
}

.btn-playback.playing{
  background:#E81954;
}

.btn-playback-img{
  width:58px;
  height:58px;
  background:#00121E;
  border-radius:4px;
  margin-right:10px;
  cursor:pointer;
}

.btn-playback-text{
  display:flex;
  flex-direction:column;
  gap:2px;
  margin-right:75px;
}

.btn-playback-text .title{
  font-size: 16px;
  font-weight: 500;
  color: #fff;
}

.btn-playback-text .composer{
  font-size: 14px;
  color: #00121e;
}

.btn-playback-switch{
  position:absolute;
  right:10px;
  width:76px;
  height:40px;
  border-radius:20px;
  background-color:#00121e;
  background-image:url('../assets/btn-pirate-switch-background.svg');
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.6);
  cursor:pointer;
}

.btn-playback-knob{
  height:36px;
  width:36px;
  background-color:#25A4FF;
  border-radius:18px;
  margin:2px;

  background-image:url('../assets/btn-pirate-switch-icons.svg');
  background-repeat:no-repeat;
  background-position-x:0px;

  transition: margin 0.15s cubic-bezier(.54,0,.45,1.01), background-position-x 0.3s;
}

.pirate .btn-playback-knob{
  margin-left:38px;
  background-position-x:-36px;
}

.btn-playback.playing .btn-playback-knob{
  background-color:#E81954;
}


/* LAYOUT --------- */
.pirates-band-wrapper{
  max-width:1104px;
  margin:auto;
  margin-bottom:20px;

  border-style: solid;
  border-image:url('../assets/bg-pirate-band-borders.svg');
  border-width: 62px;
  border-image-slice: 62;
  box-sizing: border-box;
}

.content-wrapper{
  display:flex;
  flex-direction:row;
  justify-content:center;
  align-items:center;
  gap: 50px;
  margin:-28px 0;
}

.content-right{
  width:458px;
  text-align:left;
}

.content-right > span{
  display:block;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 6px;
  color: #d69a62;
  text-transform:uppercase;
}

.content-right > h2{
  font-family: OrpheusPro;
  font-size: 50px;
  font-weight: bold;
  line-height: 0.9;
  letter-spacing: -1px;
  color: #fff;
  margin:8px 0;
}

.content-right > p{
  font-size: 16px;
  line-height: 1.38;
  letter-spacing: normal;
  color: #e4e6ee;
}

/* PIRATES IMAGES AND ANIMATIONS -------------- */
.content-left{
  width:424px;
  flex-shrink:0; /*Protect witdh*/
  position:relative;
  display:flex;
  overflow:hidden;
}

.content-left img.background{
  max-width:100%;
  position:absolute;
  top:0;
  left:0;
  z-index:1;
  animation: background-rotation 240s linear infinite;
}

  @keyframes background-rotation {
       from { transform: rotate(0deg) }
       to { transform: rotate(360deg) }
  }

.content-left img.pirates-concept{
  max-width:100%;
  height:auto;
  display:block;
  position:relative;
  z-index:2;
  transition: margin 0.4s cubic-bezier(.54,0,.45,1.01);
}

.content-left.pirate img.pirates-concept{
  margin-left:-100%;
}

.content-left .pirates-sprites{
  width:100%;
  height:424px;
  position:relative;
  z-index:2;

  image-rendering: auto;
  -ms-interpolation-mode: nearest-neighbor;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  image-rendering: pixelated;
}

.content-left .pirates-sprites #yolande{
  width:153px;
  height:156px;
  position:absolute;
  left:133px;
  top:240px;
  z-index:4;
}

.content-left .pirates-sprites #valtraid{
  width:171px;
  height:294px;
  position:absolute;
  left:40px;
  top:15px;
  z-index:2;
}

.content-left .pirates-sprites #keenathan{
  width:123px;
  height:198px;
  position:absolute;
  left:225px;
  top:90px;
  z-index:1;
}

.content-left .pirates-sprites #squid{
  width:78px;
  height:93px;
  position:absolute;
  left:323px;
  top:60px;
  z-index:3;
}

/* MEDIUM DESKTOP ------------------------------- */
@media screen and (max-width:1150px) {
  .pirates-band-wrapper{
    max-width:calc(100% - 30px);
  }

  .content-wrapper{
    gap: 25px;
  }

  .content-left{
    margin-left:-25px;
  }
}

/* MOBILE ------------------------------- */
@media screen and (max-width:767px) {
  .pirates-band-wrapper{
     border-style: none;
     border-image:none;
     max-width:calc(100% - 60px);
  }

  .content-wrapper{
    margin:auto;
    flex-direction:column;
    gap:0px;
  }

  .content-left{
    margin-left:0;
  }

  .content-right{
    width:auto;
  }

  .content-right > span{
    font-size: 14px;
  }

  .content-right > p{
    font-size: 20px;
    line-height: 1.4;
  }

  .btn-playback{
    max-width:calc(100% + 30px);
    margin-left:-15px;
    margin-right:-15px;
  }

  .btn-playback-img{
    margin-right:5px;
  }

}

/* SMALL MOBILE ------------------------------- */
@media screen and (max-width:450px) {
  .content-left{
    transform:scale(0.90);
  }

  .content-right{
    margin-top:-15px;
  }

  
}


</style>
