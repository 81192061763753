<template>
  <div class="simple-video-player">

    <!-- Thumbnail -->
    <div class="thumbnail-container" :style="{'background-image':`url(${this.posterURL})`}" v-if="thumbnailActive" @click="removeThumbnailAndStartVideo()">
      <div class="play-button">
        <svg class="play-button-icn" height="97" viewBox="0 0 138 97" width="138" xmlns="http://www.w3.org/2000/svg">
          <g fill="none" fill-rule="evenodd">
            <path id="backshape" d="m69.0299927 0h-.0599854s-28.9670194 0-48.278816 1.39514047c-2.6975881.32147773-8.5776466.34875136-13.82718256 5.84168658-4.13750871 4.18515135-5.48393849 13.68892975-5.48393849 13.68892975s-1.38007025 11.1605837-1.38007025 22.3210323v10.462946c0 11.1603136 1.38007025 22.3207623 1.38007025 22.3207623s1.34642978 9.5039134 5.48393849 13.6890648c5.24953596 5.4930702 12.14515866 5.3193021 15.21643976 5.8950186 11.0402919 1.0580007 46.9196866 1.3854192 46.9196866 1.3854192s28.9975525-.0436108 48.3086739-1.4386162c2.697453-.3216128 8.579538-.3487514 13.827182-5.8418216 4.137509-4.1851514 5.485965-13.6890648 5.485965-13.6890648s1.378044-11.1604487 1.378044-22.3207623v-10.462946c0-11.1604486-1.378044-22.3210323-1.378044-22.3210323s-1.348456-9.5037784-5.485965-13.68892975c-5.247644-5.49293522-11.129729-5.52020885-13.827182-5.84168658-19.3111214-1.39514047-48.2788163-1.39514047-48.2788163-1.39514047"/>
            <path id="triangle" d="m54.7468128 66.3888791 37.2882418-19.3075667-37.2882418-19.4425847z" />
          </g>
        </svg>
      </div>
    </div>

    <!-- Video -->
    <div v-if="!thumbnailActive" class="youtube-container">
        <youtube :player-vars="{ autoplay: 1 }" player-width="100%" player-height="100%" :video-id="this.videoId" @playing="turnMusicOff()" @ready="playerReady"></youtube>
    </div>

  </div>
</template>

<script>
import {EventBus} from "@/event-bus";

export default {
  name: "simple-video-player",
  props: {
    posterURL: String,
    videoId: String
  },
  data(){
    return{
      player:null,
      thumbnailActive:true
    }
  },
  mounted () {
    EventBus.$on('play', (data) => {
      if (data.type != 'video')
        if(this.player != null){
           this.player.stopVideo();
        }
    })
  },
  methods:{
    removeThumbnailAndStartVideo() {
      this.thumbnailActive = false;
      
    },
    playerReady: function(event) {
      this.player = event.target;
      //this.player.play(); // Can't call this
    },
    turnMusicOff:function() {
      EventBus.$emit('play', {type:'video',url:null});
    },
    pauseVideo:function() {

    }
  }
}
</script>

<style scoped>

.simple-video-player {
  max-width: 1080px;
  height:608px;
  margin:auto;
  box-shadow: 0 20px 20px -20px #000;
  border-radius:4px;
  background-color:#000;
}

.thumbnail-container{
  width:100%;
  height:100%;
  background-size:cover;

  display:flex;
  justify-content: center;
  align-content:center;
  align-items: center;
  vertical-align: center;
  border-radius:4px;
}

.youtube-container  {
    overflow: hidden;
    position: relative;
    max-width:100%;
    padding-bottom: 56.25%;
    border-radius:4px;
}

.youtube-container >>> iframe, .youtube-container >>> object, .youtube-container >>> embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }


/* PLAY BUTTON ---------------- */
.play-button {
  display:block;
  position:absolute;
  width:138px;
  height:97px;
  z-index:10;
  cursor:pointer;
  box-shadow: 0 15px 15px -10px rgba(90, 60, 13, 0.72);
  border-radius:28px;
  transition: transform 0.2s;
  transition-timing-function:cubic-bezier(0,0,0.2,1)
}

.play-button-icn #backshape{
  fill:#25A4FF;
}

.play-button-icn #triangle{
  fill:#fff;
}

.play-button:hover{
   transform:scale(1.1);
}

.play-button-icn:hover #backshape{
  fill:#1AA4FF;
}

/* MOBILE AND MEDIUM DESKTOP ------------------------------- */
@media screen and (max-width:1080px) {
  .simple-video-player {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom:56%;
  }

  .thumbnail-container{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .play-button{
    transform:scale(0.75);
  }

  .play-button:hover{
    transform:scale(0.75);
  }
}

</style>
