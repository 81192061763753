<template>
  <div id="cta-footer-wrapper">
    <div id="cta-footer-outside">
    <div id="cta-footer-inside">

      <div id="sos-logo-wrapper">
        <img id="sos-logo" :src="require('@/assets/branding-logo-sea-of-stars-white.svg')"/>
      </div>

      <div id="buttons-wrapper">
         <a href="https://store.steampowered.com/app/1244090/Sea_of_Stars/" class="cta-button" id="steam" target="_blank">
            <img class="cta-logo" id="steam-logo" :src="require('@/assets/assets-logo-steam.svg')"/>
        </a>

        <a href="https://seaofstarsgame.co/eshop" class="cta-button" id="eshop" target="_blank">
            <img class="cta-logo" id="switch-logo" :src="require('@/assets/assets-logo-switch.svg')"/>
        </a>

        <a href="https://www.playstation.com/en-ca/games/sea-of-stars/" class="cta-button" id="playstation" target="_blank">
          <img class="cta-logo" id="playstation-logo" :src="require('@/assets/assets-logo-playstation.svg')"/>
        </a>

        <a href="https://www.xbox.com/en-US/games/store/sea-of-stars/9nllttjzh40l" class="cta-button" id="xbox" target="_blank">
          <img class="cta-logo" id="xbox-logo" :src="require('@/assets/assets-logo-xbox.svg')"/>
        </a>

        <a href="https://discord.com/invite/sabotagestudio" class="cta-button" id="discord" target="_blank">
          <img class="cta-logo" id="discord-logo" :src="require('@/assets/assets-logo-discord.svg')"/>
        </a>
      </div>
    </div>

    </div>
    <div id="copyright">© 2024 Sabotage Studio - All Rights Reserved</div>
  </div>
</template>

<script>

export default {
  name: 'FooterFold',
  components: {

  },
  data (){
    return{

    }
  },
  mounted:function() {

  }
}
</script>

<style>

#cta-footer-wrapper {
  background:#026477;
  padding-top:32px;
  padding-bottom:20px;
}

#cta-footer-outside {
  max-width:630px;
  position:relative;
  background-color:#082136;
  margin:0 auto 15px auto;
  padding: 2px;
  border-radius: 3px;
  box-shadow: 0 -6px 10px 0 rgba(0, 0, 0, 0.18);
}

#cta-footer-inside {

  display:flex;
  flex-direction: row;
 justify-content: space-between;
  padding: 7px;
  border-radius: 1px;
  border: solid 1px #0c63a9;
}

.cta-button {
  display: inline-block;
  margin-right:5px;
  padding:0 16px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  font-stretch: condensed;
  font-family:"neusa-next-std-condensed";
  line-height:54px;
  letter-spacing:1.5px;
  border-radius: 4px;
  text-align:left;
  color:#fff;
  text-decoration:none;
  transition:transform 0.1s ease-in-out;
  background: #0C3B62;
  cursor: pointer;
}

.cta-button:last-child {
  margin-right:0px;
}

.cta-button:hover{
  box-shadow: 0 0 10px 0 rgba(0, 179, 200, 0.64);
  background: linear-gradient(to bottom, #3cd0ff, #3fd9be);
}

.cta-logo {
  vertical-align: middle;
}

#sos-logo-wrapper {
  display:flex;
  align-items: center;
}

#sos-logo {
  width:162px;
  margin-left:27px;
  margin-right:30px;
}

#buttons-wrapper{
  display: flex;
}

#copyright {
  font-stretch: condensed;
  font-family:"neusa-next-std-condensed";
  font-size: 15px;
  font-weight: 500;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.5px;
  text-align: center;
  color: #3e8d9d;
}



/* MOBILE */

@media screen and (max-width:767px) {
  #cta-footer-wrapper {
    display:block;
    max-width:100%;
    padding-top:0px;
  }

  #cta-footer-outside {
   margin:0 15px 15px 15px;
  }

  #cta-footer-inside {
    flex-direction: column;
    justify-content: center;
    padding:12px;
  }

  .cta-button {
    margin-top:10px;
    margin-right:0px;
    padding:0;
    text-align:center;
  }

  #sos-logo-wrapper {
    width:100%;
  }

  #sos-logo {
    margin:13px auto 10px auto;
    width:215px;
  }

  #buttons-wrapper{
    flex-direction: column;
  }


/*
.cta-button#spotify {
  background: linear-gradient(103deg, #1DB954, #209843);
}

.cta-button#spotify:hover {
  background: linear-gradient(103deg, #1DB954, #2cd964);
  transform: scale(1.03);
}


.cta-button#steam {
  background: linear-gradient(103deg, #153051, #0c87b6);
}

.cta-button#steam:hover {
  background: linear-gradient(103deg, #006A94, #039FDB);
  transform: scale(1.03);
}


.cta-button#discord {
  background: linear-gradient(103deg, #6f7af4, #5865f2);
}

.cta-button#discord:hover {
   background: linear-gradient(103deg, #9EA6FF, #6D78F4);
   transform: scale(1.03);
}

.cta-button#website {
  background: linear-gradient(103deg, #00b2c9, #0081d2);
}

.cta-button#website:hover {
  background: linear-gradient(103deg, #11D9F3, #0097F5);
  transform: scale(1.03);
}

.cta-button#eshop {
  background: linear-gradient(103deg, #F12030, #E60012);
}

.cta-button#eshop:hover {
  background: linear-gradient(103deg, #FF3242, #E60012);
  transform: scale(1.03);
}

.cta-button#playstation {
  background-image: linear-gradient(275deg,#144bb4,#215fd6);
}

.cta-button#eshop:hover {
  background: linear-gradient(103deg, #FF3242, #E60012);
  transform: scale(1.03);
}

.cta-button#xbox {
  background-image: linear-gradient(275deg,#107C10, #107710);
}

.cta-button#xbox:hover {
  background: linear-gradient(103deg, #107C10, #107710);
  transform: scale(1.03);
}
*/



}


</style>
