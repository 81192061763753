<template>
<div class="sfx-player-wrapper">

  <div class="border" id="top"></div>
  <div class="border" id="bottom"></div>

  <div class="sfx-player-title">{{this.$locData.recordingSoundEffects[language]}}</div>
  <p class="sfx-player-description">{{this.$locData.sfxIntro[language]}}</p>
  <div class="sfx-player-instructions">
    <div></div>
    <div>{{this.$locData.clickSfx[language]}}</div>
    <div></div>
  </div>

  <SfxPlayer :language="language"/>

  <!--
  <div class="sfx-video-title">{{this.$locData.ericProcess[language]}}</div>
   <p class="sfx-video-description">{{this.$locData.ericProcessIntro[language]}}</p>
  <SimpleVideoPlayer posterURL="https://audio-sea-of-stars.fra1.digitaloceanspaces.com/video/img-video-poster-eric.webp" videoId="b8BiyYO6loU" />
  -->

</div>
</template>

<script>
//import SimpleVideoPlayer from './simple-video-player.vue';
import SfxPlayer from './sfx-player.vue';


export default {
  name: "sfx-fold.vue",
  props: {
    language: String
  },
  components: {
    SfxPlayer
  },
  data () {
    return {
    }
  },
  mounted() {

  },
  methods: {

  }

}
</script>

<style scoped>

.sfx-player-wrapper{
  background-color:#F8F0E5;
  background-image:url("../assets/bg-cloud-left.svg"), url("../assets/bg-cloud-right.svg"), url("../assets/bg-pattern-audio-3.svg"), url("../assets/bg-pattern-paper.webp");
  background-size:491px 212px, 550px 246px, 1180px 580px, 500px 500px;
  background-repeat:no-repeat, no-repeat, repeat-x, repeat;
  background-position:top 78px left -87px, top 78px right -34px, bottom, top left;
  padding-top:75px;
  padding-bottom:100px;
}

.border {
  position: absolute;
  display:block;
  width:100%;
  height:7px;
  pointer-events: none;
}

.border#top {
  top:-7px;
  background-image:url("../assets/bg-pattern-paper-edge-top@2x.png");
  background-size:1450px 7px;
}

.border#bottom {
  bottom:-7px;
  background-image:url("../assets/bg-pattern-paper-edge-bottom@2x.png");
  background-size:1450px 7px;
  z-index:2;
}

.sfx-player-title{
  font-size:50px;
  text-align: center;
  font-family:"orpheuspro";
  letter-spacing: -1px;
  line-height: 0.9;
  color:#584b3b;
  margin-bottom:20px;
}

.sfx-player-description{
  max-width:600px;
  margin:auto;
  font-size: 16px;
  line-height: 1.38;
  text-align: center;
  color: #584b3b;
}

.sfx-player-instructions {
  display:inline-flex;
  align-items:center;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 4px;
  color: #bd9e79;
  margin:30px 0;
}

.sfx-player-instructions div:first-child, .sfx-player-instructions div:last-child{
  width:16px;
  height:1px;
  background-color:#BD9E79;
  margin:0 10px;
}

.sfx-video-title{
  font-size:40px;
  text-align: center;
  font-family:"orpheuspro";
  letter-spacing: -0.8px;
  line-height: 0.9;
  color:#584b3b;
  margin-top:48px;
  margin-bottom:16px;
}

.sfx-video-description{
  max-width:600px;
  margin:auto;
  font-size: 16px;
  line-height: 1.38;
  text-align: center;
  color: #584b3b;
  margin-bottom:20px;
}

/* MOBILE ------------------------------- */
@media screen and (max-width:767px) {
  .sfx-player-wrapper{
    background-position:top 30px left -300px, top 30px right -350px, bottom, top left;
    padding-top:42px;
    padding-bottom:42px;
  }

  .sfx-player-title{
    max-width:350px;
    margin-left:auto;
    margin-right:auto;
  }

  .sfx-player-description, .sfx-video-description{
    margin:0 30px 30px 30px;
    font-size: 20px;
    line-height: 1.4;
    color: #584b3b;
    text-align:left;
  }

  .sfx-player-instructions {
    display:none;
  }
}

</style>
