<template>
  <div class="intro">
    <div id="logo">
      <img v-if="isLoaded" src="@/assets/img-audio-title.svg" width="186" height="186" >
    </div>

    <div class="title-wrapper">
      <h1>{{this.$locData.mainTitle[language]}}</h1>
    </div>
    <div id="preface">
      <div></div>
      <div>{{this.$locData.preface[language]}}</div>
      <div></div>
    </div>

    <div class="intro-wrapper">
      <div class="intro-text">{{this.$locData.ericFirstParagraph[language]}}</div>
      <div class="intro-text">{{this.$locData.ericSecondParagraph[language]}} </div>
      <div class="intro-text">
        {{this.$locData.ericThirdParagraph[language]}}
        <div class="signature"><img src="@/assets/img-eric-signature.png" /></div>
      </div>
    </div>



    <div id="ctas">
      <a class="cta-ost-button" id="steam" href="https://store.steampowered.com/app/2550490/Sea_of_Stars__OST/" target="_blank">
        <svg class="cta-ost-logo" id="steam-logo" xmlns="http://www.w3.org/2000/svg" width="42" height="40" viewBox="0 0 42 40">
          <g>
          <path d="M20.972 4.002c-8.498 0-15.466 6.48-16.126 14.716l8.674 3.544c.736-.495 1.623-.787 2.579-.787.084 0 .168.005.253.008l3.86-5.523v-.079c0-3.327 2.734-6.032 6.1-6.032 3.363 0 6.101 2.708 6.101 6.036 0 3.328-2.738 6.033-6.1 6.033h-.142l-5.498 3.881c0 .069.005.14.005.212 0 2.5-2.043 4.528-4.571 4.528-2.205 0-4.067-1.564-4.492-3.636l-6.21-2.543C7.326 31.076 13.563 36 20.971 36c8.937 0 16.182-7.164 16.182-16S29.908 4 20.972 4v.002zm-5.987 24.28l-1.987-.813c.353.724.963 1.332 1.772 1.667 1.75.719 3.767-.101 4.494-1.833.355-.84.356-1.759.007-2.599-.349-.84-1.011-1.495-1.857-1.844-.841-.347-1.74-.332-2.532-.04l2.054.84c1.29.533 1.9 2 1.36 3.273-.535 1.276-2.018 1.88-3.31 1.349h-.001zm15.394-12.404c0-2.216-1.824-4.02-4.066-4.02-2.245 0-4.066 1.804-4.066 4.02 0 2.22 1.82 4.02 4.066 4.02 2.243 0 4.066-1.8 4.066-4.02zm-7.11-.007c0-1.669 1.366-3.021 3.054-3.021 1.684 0 3.056 1.352 3.056 3.021 0 1.668-1.372 3.02-3.056 3.02-1.69 0-3.054-1.352-3.054-3.02z" transform="translate(-494 -3547) translate(0 3498) translate(240 32) translate(228 10) translate(26 7)"/>
          </g>
        </svg>
        <div class="cta-ost-content">
          <span>Buy the original</span>
          <span>Soundtrack on Steam</span>
        </div>
      </a>

      <a class="cta-ost-button" id="spotify" href="https://open.spotify.com/album/0sKlNbj6o886BljTU9kFTT?si=MNS-BWuFRBqgYZGjgBmDCQ&nd=1&dlsi=6e6441f4d7874d8d" target="_blank">
        <svg class="cta-ost-logo" id="spotify-logo" width="42px" height="40px" viewBox="0 0 42 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <g id="assets/logo/spotify">
            <path d="M21,4.03846154 C12.0786678,4.03846154 4.84615385,11.2707352 4.84615385,20.1920156 C4.84615385,29.114255 12.0786678,36.3461538 21,36.3461538 C29.9217289,36.3461538 37.1538462,29.114266 37.1538462,20.1920156 C37.1538462,11.2713083 29.9221037,4.03923313 20.9998015,4.03923313 L21,4.03846154 Z M28.4080513,27.3365266 C28.1179325,27.8124092 27.4979576,27.9615131 27.0236154,27.6715833 C23.230278,25.3531588 18.4556618,24.8294377 12.8326903,26.1139449 C12.2908448,26.2381817 11.7507299,25.8984843 11.627286,25.3568183 C11.503247,24.814976 11.841392,24.2748641 12.3845933,24.1514211 C18.5380348,22.7446171 23.8163026,23.3501266 28.074139,25.9519113 C28.5486686,26.243197 28.6991181,26.8623965 28.4078529,27.3369123 L28.4080513,27.3365266 L28.4080513,27.3365266 Z M30.3852468,22.9386826 C30.0198976,23.5314498 29.2448326,23.7174026 28.6528336,23.3534003 C24.3116652,20.6848732 17.6919833,19.911929 12.5560853,21.4709342 C11.8900023,21.6721204 11.1865081,21.2967427 10.9843508,20.6318319 C10.7837367,19.9657528 11.159315,19.2636075 11.8242296,19.0610655 C17.690837,17.2810223 24.9839099,18.1432613 29.9703173,21.2074368 C30.5625257,21.5720123 30.7496259,22.3474585 30.3850484,22.93887 L30.3852468,22.9386826 Z M30.5549972,18.3585344 C25.3479033,15.2662068 16.7589292,14.9818765 11.7881411,16.4905191 C10.9899394,16.7325994 10.1458169,16.2819918 9.90392252,15.4837838 C9.66202819,14.6852008 10.1122526,13.8416564 10.9110385,13.5989919 C16.6171545,11.8667871 26.1029179,12.2014581 32.0970144,15.759825 C32.8149815,16.1859291 33.0505157,17.1131798 32.6249712,17.8301838 C32.2005952,18.5481467 31.2708259,18.7848259 30.5551846,18.3585344 L30.5549972,18.3585344 L30.5549972,18.3585344 Z" id="Shape" ></path>
          </g>
        </svg>
        <div class="cta-ost-content">
          <span>Listen to the</span>
          <span>Soundtrack on Spotify</span>
        </div>
      </a>
    </div>

    <div class="clouds"></div>
  </div>
</template>

<script>
import {EventBus} from "@/event-bus";
import { gsap } from "gsap";

export default {
name: "intro-fold",
  props: {
   language: String
  },
  data(){
    return{
      isLoaded:false
    }
  },
  mounted () {
    EventBus.$on('appLoaded', this.appLoaded);
  },
  methods:{
    appLoaded:function () {
      this.isLoaded = true;
      gsap.to("#logo", 1, {opacity:1});
    }
  }

}
</script>

<style scoped>

.intro {
  background-color:#00121e;
  background-image:url("../assets/bg-pattern-sky-reversed-lg.png");
  background-size:1280px 664px;
  background-repeat:repeat-x;
  background-position: top center;
  padding:150px 0 56px 0;

  position:relative;
  overflow-x:hidden;
}

#preface {
  display:inline-flex;
  align-items:center;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: bold;
  font-stretch: condensed;
  letter-spacing: 1px;
  color:#fff;
}

#preface div:first-child, #preface div:last-child{
  width:16px;
  height:1px;
  background-color:#D59A62;
  margin:0 10px;
}

#logo {
  width:186px;
  height:186px;
  margin:auto;
  opacity:0;
  position:relative;
  z-index:8;
}

.title-wrapper {
  max-width:250px;
  margin:-50px auto 32px auto;
  position:relative;
  z-index:10;
}

h1 {
  font-family:"orpheuspro";
  font-size:50px;
  text-align: center;
  text-shadow: 0 0 10px rgba(60, 208, 247, 0.59);
  letter-spacing: -1px;
  line-height: 0.9;
  font-size:50px;
  color:white;
  margin:0px;
}

.intro-wrapper {
  display:flex;
  flex-direction: row;
  justify-content: center;
  margin:38px auto 38px auto;
  gap:15px;
}

.intro-text {
  width:340px;
  font-size:16px;
  text-align:left;
  line-height: 1.38;
  color: #e4e6ee;
}

.signature{
   margin-top:5px;
}

.signature img{
  width:60px;
}

.clouds {
    background-image:url("../assets/bg-pattern-sky-clouds-reversed-lg.png");
    background-size:1280px 368px;
    background-repeat:repeat-x;
    position:absolute;
    width:calc(100% + 1280px);
    height:368px;
    top: 0;
    left: 0;
    z-index:1;
    animation: clouds-slide 60s linear infinite;
}


/* CALL TO ACTION BUTTONS */

#ctas {
  display:flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}


.cta-ost-button{
  width:240px;
  height:70px;
  border-radius: 4px;
  box-sizing: border-box;
  text-align: left;
  border:3px solid;
  cursor:pointer;
  display:flex;
  justify-content:left;
  align-items:center;
  transition: all 0.3s;
}

.cta-ost-button#steam{
  border-color:rgba(84,143,183,0.3);
}


.cta-ost-button:hover#steam{
  border-color:rgba(84,143,183,1);
}


.cta-ost-button#steam .cta-ost-logo{
  fill:#548FB7;
}

.cta-ost-button#spotify{
  border-color:rgba(30,215,96,0.3);
}

  
.cta-ost-button:hover#spotify{
   border-color:rgba(30,215,96,1);
}


.cta-ost-button#spotify .cta-ost-logo{
  fill:#1ED760;
}

.cta-ost-button .cta-ost-logo{
  padding: 0 8px;
}

.cta-ost-button .cta-ost-content span:nth-child(1){
  display:block;
  font-size:13px;
  opacity: 0.7;
  margin-bottom: 3px;
}

.cta-ost-button .cta-ost-content span:nth-child(2){
  display:block;
  font-size:18px;
  font-weight:500;
}


/* CALL TO ACTION DISABLED */
.cta-ost-button.disabled {
  cursor: not-allowed;
}

.cta-ost-button.disabled .cta-ost-content {
  opacity:0.4;
}

.cta-ost-button.disabled .cta-ost-content span:nth-child(1){
  opacity:1.0;
}


  @keyframes clouds-slide {
     from { transform:translate3d(-1280px, 0, 0) }
     to { transform:translate3d(0, 0, 0) }
  }

/* SMALL DESKTOP ------------------------- */
@media screen and (max-width:1100px) {
.intro-text {
    width:30%;
  }
}

/* MOBILE ------------------------------- */
@media screen and (max-width:767px) {
  .intro {
    padding:100px 30px 40px 30px;
  }

  .title-wrapper {
    margin-bottom:26px;
    max-width:250px;
  }

  #preface {
    font-size: 14px;
    letter-spacing: 2px;
  }

  #preface div:first-child, #preface div:last-child{
    height:2px;
    margin:0 15px;
  }

  .intro-wrapper {
    flex-direction: column;
    margin-top:26px;
    margin-bottom: 20px;
  }

  .intro-text {
    width:auto;
    font-size: 20px;
    line-height: 1.4;
    margin:0;
  }

  .signature{
    text-align:center;
    margin-top:20px;
  }

  .signature img{
    width:100px;
  }

  #coming-soon {
    font-size: 16px;
    margin-bottom: 15px;
  }

  #ctas {
    flex-direction: column;
    gap: 15px;
  }

  .cta-ost-button{
    width: 100%;
  }

  .cta-ost-button .cta-ost-content span:nth-child(1){
    font-size: 15px;
  }

  .cta-ost-button .cta-ost-content span:nth-child(2){
    font-size: 20px;
  }


  .clouds {
    background-image:url("../assets/bg-pattern-sky-clouds-reversed-xs.png");
    background-size:640px 298px;
    height:298px;
    width:calc(100% + 640px);
}

  @keyframes clouds-slide {
      from { transform:translate3d(-640px, 0, 0) }
      to { transform:translate3d(0, 0, 0) }
  }
}

</style>
