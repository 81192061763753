<template>
  <div class="collaborator-wrapper">

    <div class="portrait-wrapper" v-on:click="setActive(id)" v-bind:class="{active:active}">
      <img class="portrait" :src="this.portraitUrl" width="420" height="126" />
    </div>

    <div class="details-wrapper" :style="{'background-image':`url(${this.backgroundUrl})`}" v-bind:class="{active:active}">
      <div class="details-inner-wrapper" :id="this.id">
        <p class="collaborator-name"> {{name}} </p>
        <p class="collaborator-title"> {{title}} </p>
        <p class="collaborator-description" v-html="description"></p>
        <div class="collaborator-data">
          <div>known for</div>
          <div>
            <a v-for="(link, i) in knownFor" v-bind:key="i" :href="link.link" target="_blank">
              {{link.title}}<span v-if="i < knownFor.length - 1">,&nbsp;</span>
            </a>
          </div>
        </div>
        <div class="collaborator-data">
          <div>listen on</div>
          <div>
            <a v-for="(listen, j) in listenOn" v-bind:key="j" :href="listen.link" target="_blank" class="platform">
               <svg v-if="listen.title == 'spotify'" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="m11.9998209 2.00047766c5.5234137 0 10.0001791 4.47711111 10.0001791 9.99958204 0 5.523068-4.4767654 9.9999403-10.0000597 9.9999403-5.52269729 0-9.9999403-4.4768723-9.9999403-9.9999403 0-5.52282918 4.47724301-10.0000597 9.9999403-10.0000597zm4.3794444 13.56507824c-2.6359061-1.6109098-5.9033836-1.9856344-9.71263446-1.1153371-.16123673.0366288-.30127697.1359012-.38922745.2759174-.08795049.1400162-.11658441.3092713-.07958505.470427.07659019.3355721.41058191.5456247.74620717.4693014 3.48086719-.7953046 6.43655929-.4704955 8.78444299.9636799.1410463.0864741.3106962.1132907.4715365.0745358.1608403-.038755.2996595-.1398981.3858435-.2811239.1803125-.2937612.0871709-.6770837-.2065832-.8574005zm1.1738224-2.9372533c-3.0868067-1.896909-7.6016646-2.4306945-11.23334945-1.3287319-.41169662.1254186-.64425196.5603619-.51992095.9723972.12514404.4116239.56064053.6440057.97297104.5194557 3.17935123-.9651129 7.27674386-.4866165 9.96471366 1.1656108.3665956.2256946.8466329.1098619 1.072322-.2569813.2256892-.3660073.1098593-.846056-.2567363-1.0717505zm1.3164007-3.372402c-3.7106164-2.20285044-9.5827139-2.41003565-13.11504776-1.33768801-.49429557.15011483-.7733888.67244487-.62345137 1.16680499.14974297.49413971.67229098.77309339 1.16641889.62322744 3.07713434-.93394554 8.39456194-.75792767 11.61749864 1.15641588.4434971.2639074 1.0190642.117385 1.2817712-.327078.126885-.213274.1636971-.4682715.1023136-.70872585-.0613835-.24045434-.2159175-.44660238-.4295032-.57295645z" fill-rule="evenodd"/></svg>

               <svg v-if="listen.title == 'apple-music'" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="m19.7635764 6.81868187c-.1160116.090009-2.1642165 1.24412441-2.1642165 3.81038103 0 2.9682968 2.6062607 4.0184018 2.6842685 4.0444044-.0120012.0640064-.4140414 1.4381439-1.3741375 2.8382839-.8560856 1.2321232-1.750175 2.4622462-3.110311 2.4622462s-1.710171-.790079-3.280328-.790079c-1.530153 0-2.0742074.8160816-3.31833185.8160816-1.24412441 0-2.11221122-1.140114-3.11031103-2.540254-1.15611561-1.6441644-2.09020902-4.1984199-2.09020902-6.6226623 0-3.88838883 2.52825283-5.95059505 5.01650165-5.95059505 1.32213225 0 2.42424245.86808681 3.25432545.86808681.790079 0 2.0222022-.92009201 3.5263526-.92009201.570057 0 2.6182618.0520052 3.9663967 1.98419842zm-4.6804681-3.63036304c.6220622-.73807381 1.0621062-1.76217622 1.0621062-2.78627863 0-.1420142-.0120012-.2860286-.0380038-.4020402-1.0121012.0380038-2.2162216.67406741-2.9422942 1.51615162-.570057.6480648-1.1021102 1.67216721-1.1021102 2.71027102 0 .1560156.0260026.31203121.0380038.36203621.0640064.0120012.1680168.0260026.2720272.0260026.9080908 0 2.050205-.60806081 2.710271-1.42614262z"/></svg>

              <svg v-if="listen.title == 'bandcamp'" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="m12 2 .279905.00384179c5.3934281.14822636 9.720095 4.5669143 9.720095 9.99615821l-.0038418.279905c-.1482263 5.3934281-4.5669143 9.720095-9.9961582 9.720095l-.279905-.0038418c-5.39342814-.1482263-9.720095-4.5669143-9.720095-9.9961582 0-5.52285156 4.47714844-10 10-10zm5.3516836 6.85527344h-7.2962109l-3.40714848 6.28945316h7.29613278z" fill-rule="evenodd"/></svg>

              <svg v-if="listen.title == 'twitch'" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="m5.10540722 3.8933278v13.409554h4.38959351v2.5412313c.06776337-.0656905.10576979-.1010854.14230309-.13798.74038848-.7519909 1.48225018-1.5018821 2.21821928-2.2580723.1048859-.1076844.2097719-.151478.3588513-.1511781 1.5341038.0053992 3.0682077.0062991 4.6020169-.0029995.1128408-.0006.2527869-.0599913.3332191-.1403797.9560528-.9604606 1.9017938-1.9317197 2.8557842-2.8945799.1069483-.1076844.145544-.2156687.145251-.365647-.0035371-3.2557275-.0023586-6.51145504-.0023586-9.76718257v-.23276623zm1.90326694 19.0898297v-2.5112355h-4.99592839c-.0050086-.1052848-.01237418-.186573-.01237418-.2681611-.00058925-4.7246144-.00088387-9.4489288.00235698-14.1735432.00029463-.13678015.0238645-.27925947.06570101-.40884067.36827921-1.14073446.74628099-2.27786944 1.1122032-3.41920381.04831824-.15027819.10930527-.20247062.26958038-.20217195 6.11372944.00480059 12.22745884.00390072 18.34118834.00390072h.2050578v.26366174c0 4.0719091-.0008838 8.14381817.0035407 12.21572727.0002894.1700753-.0489127.2870584-.166762.406441-1.7736327 1.798839-3.5440245 3.6009775-5.3105862 5.4067154-.1172601.1196827-.2321632.1697754-.3992146.1688755-1.0762592-.0077988-2.1522237.0008999-3.2284828-.0086987-.2062364-.0017998-.3535481.0530923-.5002705.2057701-.7097477.7387928-1.4301018 1.4670871-2.1525183 2.1929818-.0686473.0692899-.176774.13738-.26692879.1382799-.96165066.0095986-1.92330132.0056992-2.8852466.0047993-.02091826 0-.04242577-.0077988-.08131605-.0152978zm3.12689664-9.5594627h1.8549488v-5.66767754h-1.8549488zm5.0233284.0074989h1.8219509v-5.67517644h-1.8219509z"/></svg>

              <svg v-if="listen.title == 'youtube'" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="m12.360692 4.00092662c.7816048.00317699 2.7441298.0172465 4.7974707.07955436l.5145963.01660354c.9445823.0323615 1.889159.07551018 2.7287729.13304174.4691223.05302726 1.4920936.057526 2.4047274.96357717.7195668.69033425.9540809 2.2579678.9540809 2.2579678l.0040362.03184114c.0314165.25137735.2356236 1.95068747.2356236 3.64997863v1.7258467c0 1.6992706-.2042071 3.3985602-.2356236 3.6499345l-.0040362.0318408s-.2345141 1.5676558-.9540809 2.25799c-.9126338.9060735-1.9356051.9105499-2.4047274.9635995-.6106283.0418371-1.2769458.0763052-1.9585157.104701l-.5134055.0201785c-.1716673.0063602-.343656.0123611-.5153343.018023l-.513472.0159889c-2.1303222.0625517-4.1134275.0752992-4.7152869.0778109l-.2249284.000238c-.2852894-.002644-2.07538794-.0202347-3.95607934-.0616784l-.77084147-.0183074c-1.366619-.0349925-2.67915279-.0832437-3.39359028-.1481798-.53413586-.0949635-1.73337459-.0663007-2.64633736-.9723742-.71956673-.6903342-.95372843-2.25799-.95372843-2.25799s-.24001222-1.8408988-.24001222-3.6817753v-1.7258467c0-1.84089875.24001222-3.68181977.24001222-3.68181977s.2341617-1.56763355.95372843-2.2579678c.91296277-.90605117 1.93558165-.91054991 2.40472741-.96357717.83964333-.05753156 1.78423473-.10068024 2.72882246-.13304174l.77071469-.02414349c1.95872057-.05592146 3.79228729-.0689698 4.54131029-.07201441zm-3.360692 4.99907338v6l6-3z" fill-rule="evenodd"/></svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { EventBus } from '../event-bus.js'

export default {
  name: "collaborators-view.vue",
  props: {
    id: Number,
    name:String,
    title:String,
    portraitUrl:String,
    backgroundUrl:String,
    description:String,
    knownFor:Array,
    listenOn:Array
  },
  data() {
    return {
      active: false
    }
  },
  methods: {
    setActive: function (id) {
      EventBus.$emit('collaboratorClicked', id);
      console.log("Emitter ID:"+id);
    }
  },
  beforeMount(){
    if (this.id==0) {
      this.active = true;
    }
  },
  mounted() {
    EventBus.$on('collaboratorClicked', (id) => {
      console.log("Receiver ID:"+id);
      if (this.id != id) {
        this.active = false;
      } else {
        this.active = true;
      }
    });
  }

}
</script>

<style scoped>

.collaborator-wrapper {
  display:flex;
  flex-direction: row;
  border-radius:4px;
  box-shadow: 0 20px 20px -20px #000000;
}

.portrait-wrapper {
  display:block;
  position:relative;
  cursor:pointer;
  background:#000;
  z-index:99;
}

.portrait-wrapper.active{

}

.portrait{
  height:420px;
  width:126px;
  border-radius:4px;
  opacity:0.7;
  transition: opacity 0.3s;
  display:block;
  overflow:hidden;
}

.portrait-wrapper.active .portrait,
.portrait:hover{
  opacity:1;
  box-shadow: 4px 0 4px 0 rgba(0,0,0,0.25);
}

.details-wrapper {
  position:relative;
  display:block;
  text-align:left;
  width:0px;
  height:420px;
  border-radius:4px;
  background-size: cover;
  overflow:hidden;
  transition: width 0.4s cubic-bezier(.54,0,.45,1.01);
}

.details-wrapper.active{
  width:765px;
}

.details-inner-wrapper {
  width:530px;
  margin:auto;
  margin-top:48px;
  opacity:0;
  transition: opacity 0.4s cubic-bezier(.54,0,.45,1.01);
}

.details-wrapper.active .details-inner-wrapper{
  opacity:1;
}

.collaborator-name {
  font-family: OrpheusPro;
  font-size: 32px;
  font-weight: bold;
  margin-bottom:8px;
}

.collaborator-title {
  font-size: 13px;
  font-weight: 500;
  font-stretch: condensed;
  text-transform: uppercase;
  letter-spacing:2px;
  margin-bottom:18px;
}

.collaborator-description {
  font-size: 16px;
  font-stretch: condensed;
  line-height: 1.38;
  color:white;
  margin-bottom:22px;
}

.collaborator-description >>> p{
  margin-bottom:10px;
}

.collaborator-data{
  display:flex;
  align-items:center;
  color:#fff;
  font-size: 14px;
  font-weight: 500;
  font-stretch: condensed;
  margin-bottom:8px;
}

.collaborator-data div:first-child{
  width:75px;
  text-transform: uppercase;
}

.collaborator-data a.platform{
  margin-right:12px;
  display:inline-block;
}

.collaborator-wrapper:nth-child(1) .collaborator-name,
.collaborator-wrapper:nth-child(1) .collaborator-title,
.collaborator-wrapper:nth-child(1) .collaborator-data a,
.collaborator-wrapper:nth-child(1) .collaborator-data svg{
  color:#d69a62;
  fill:#d69a62;
}
.collaborator-wrapper:nth-child(2) .collaborator-name,
.collaborator-wrapper:nth-child(2) .collaborator-title,
.collaborator-wrapper:nth-child(2) .collaborator-data a,
.collaborator-wrapper:nth-child(2) .collaborator-data svg{
  color:#BFD6DB;
  fill:#BFD6DB;
}
.collaborator-wrapper:nth-child(3) .collaborator-name,
.collaborator-wrapper:nth-child(3) .collaborator-title,
.collaborator-wrapper:nth-child(3) .collaborator-data a,
.collaborator-wrapper:nth-child(3) .collaborator-data svg{
  color:#D7B3B5;
  fill:#D7B3B5;
}
.collaborator-wrapper:nth-child(4) .collaborator-name,
.collaborator-wrapper:nth-child(4) .collaborator-title,
.collaborator-wrapper:nth-child(4) .collaborator-data a,
.collaborator-wrapper:nth-child(4) .collaborator-data svg{
  color:#D4B89A;
  fill:#D4B89A;
}

/* SMALL DESKTOP ----------------------------- */
@media screen and (max-width:1350px) {
  .details-wrapper.active{
    width:560px;
  }

  .details-inner-wrapper{
    width:450px;
  }
}


@media screen and (max-width:1150px) {
  .details-wrapper.active{
    width:490px;
  }

  .details-inner-wrapper{
    width:400px;
    margin-top:40px;
  }
}

/* MOBILE ------------------------------- */
@media screen and (max-width:767px) {
  .collaborator-wrapper {
    margin:0px;
    flex-wrap: nowrap;
    justify-content:flex-start;
    align-items:stretch;
  }

  .portrait-wrapper{
    display:none;
  }

  .details-wrapper {
    height:0;
    width:0;
    background-position:center top;
    transition: none;
  }

  .details-wrapper.active{
    height:auto;
    width:calc(100%);
    border-radius:12px;
  }

  .details-inner-wrapper {
    width:auto;
    margin:40px 30px;
  }

  .collaborator-name {
    margin-bottom:20px;
    font-size:36px;
  }

  .collaborator-description {
    font-size: 20px;
    line-height: 1.4;
    margin-bottom:24px;
  }

  .collaborator-description >>> p{
    margin-bottom:15px;
  }

  .collaborator-data{
    flex-direction:column;
    font-size:18px;
    align-items:flex-start;
    margin-bottom:25px;
  }

  .collaborator-data div:first-child{
   margin-bottom:10px;
   width:auto;
  }

  .collaborator-data a.platform{
   margin-right:20px;
  }

  .collaborator-data a.platform svg{
    width:40px;
    height:40px;

  }

}

</style>
